import React from "react";
import "./index.css";
function Carousel({ children, className }) {
  return (
    <div
      className="main-carousel"
      style={{ background: "#7367F0D9", height: "300px", borderRadius: "20px" }}
    >
      <div
        className={className}
        style={{ position: "absolute", right: "50%", left: "50%", top: -122 }}
      >
        {children}
      </div>
    </div>
  );
}

export default Carousel;
