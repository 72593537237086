import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import $ from "jquery";

function LinkEditor({ setTemplateDataToHtml }) {
  const state = useSelector((state) => state.template);

  const [url, setURL] = useState("");
  const [target, setTarget] = useState("_blank");

  const selectedTarget = state.textProperties.target || '_blank';
  const selectedHref = state.textProperties.href || '';

  useEffect(() => {
    setURL(selectedHref);
  }, [selectedHref]);

  useEffect(() => {
    setTarget(selectedTarget);
  }, [selectedTarget]);

  const handleLinkEdit = ($event) => {
    setURL($event.target.value);
    if ($event.target.value) {
      $(".active").find(".--text").attr("href", $event.target.value);
      $('.active').find('.--text').addClass('--link');
    } else {
      $('.active').find('.--text').removeAttr('href');
      $('.active').find('.--text').removeClass('--link');
    }
    setTemplateDataToHtml();
  };
  const handleLinkTarget = ($event) => {
    setTarget($event.target.value);
    $(".active").find(".--text").attr("target", $event.target.value);
    $('.active').find('.--text').addClass('--link');
    setTemplateDataToHtml();
  };
  return (
    <div>
      <Grid mt={ 2 }>
        <div className="field">
          <label>Link URL</label>
          <input
            type="url"
            value={ url }
            className="form-control mt-2"
            onChange={ handleLinkEdit }
            placeholder="Link URL"
          />
        </div>
      </Grid>

      <Grid mt={ 2 }>
        <div className="field">
          <label>Target</label>
          <select
            className="form-control mt-2"
            value={ target } onChange={ handleLinkTarget }
          >
            <option value="_blank">Blank</option>
            <option value="_self">Self</option>
          </select>
        </div>
      </Grid>
    </div>
  );
}

export default LinkEditor;