import $ from 'jquery';
export const parseFloatWithTrim = (value, defaultDecimalPoints = 2) => {
  const floatValue = parseFloat(value);
  const decimalPortion = floatValue % 1;
  const trimmedValue =
    decimalPortion === 0
      ? floatValue.toFixed(0)
      : defaultDecimalPoints
      ? floatValue.toFixed(defaultDecimalPoints)
      : floatValue;
  return trimmedValue;
};

export const checkIfElementsAreNotEmpty = (elements) => {
  let value = false;
  $(elements).each((index, element) => {
    if ($(element).hasClass('--image') && !$(element).hasClass('d-none')) {
      value = true;
    } else if (
      $(element).hasClass('--text') &&
      !$(element).hasClass('d-none')
    ) {
      value = true;
    }
  });
  return value;
};

export const setPlace = (place) => {
  if (!place) return;
  // Initialize variables to store address components

  let address = '';
  let city = '';
  let state = '';
  let zipCode = '';

  // Extract the address from the formatted_address property
  address = place.formatted_address.split(', ').slice(0, -3).join(', ');

  // If the address is empty, use the first component from formatted_address
  if (!address) {
    address = place.formatted_address.split(', ')[0];
  }

  // Extract address components from the address_components array
  const addressComponents = place.address_components || [];
  addressComponents.forEach((addressComponent) => {
    const types = addressComponent.types || [];
    if (types.includes('locality')) {
      city = addressComponent.long_name;
    } else if (!city && types.includes('neighborhood')) {
      city = addressComponent.long_name;
    }
    if (types.includes('administrative_area_level_1')) {
      state = addressComponent.short_name;
    }
    if (types.includes('postal_code')) {
      zipCode = addressComponent.short_name;
    }
  });

  // Extract latitude and longitude from the geometry
  const latitude = place.geometry.location.lat();
  const longitude = place.geometry.location.lng();

  // Return the extracted address components as an object
  return {
    address,
    city,
    state,
    zipCode,
    latitude,
    longitude,
  };
};
