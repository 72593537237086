/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
// import { getTemplateContent } from "../../api/templateApi";
import Footer from "../../layouts/template/Footer";
import TemplateNavbar from "../../layouts/template/Navbar/Navbar";
import WebsiteBuilder from "../../layouts/websiteBuilder/WebsiteBuilder";
import { setAccessLevel } from "../../stores/actions/accessLevelActions";
import Loader from "../../components/ui/Loader/Loader";
function Main() {
  const data = useSelector((state) => state.template.isLoadingContent);
  return (
    <WebsiteBuilder>
      {data ? (
        <Loader></Loader>
      ) : (
        <>
          <TemplateNavbar />
          <Outlet />
          <Footer />
        </>
      )}
    </WebsiteBuilder>
  );
}

const mapDispatchToProps = (dispatch) => ({
  accessLevel: (accessLevel) => dispatch(setAccessLevel(accessLevel)),
});

export default connect(null, mapDispatchToProps)(Main);
