import {
  getCarrierWebsiteImageUploadUrls,
  uploadFileToS3,
} from "../../api/templateApi";
import {
  FETCH_IMAGE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILURE,
} from "../contents/actionsTypes";
import $ from "jquery";
import { setTemplateDataToHtml } from "./templateActions";
export const uploadingImage = () => ({
  type: FETCH_IMAGE,
});

export const uploadImageSuccess = (data) => ({
  type: UPLOAD_IMAGE_SUCCESS,
  payload: data,
});

export const uploadImageFailure = () => ({
  type: UPLOAD_IMAGE_FAILURE,
});

export const setReplaceImages = (payload) => async (dispatch, getState) => {
  try {
    const state = getState();
    dispatch(uploadingImage());
    if (!state.user.token) {
      dispatch(
        uploadImageSuccess({
          id: payload.id,
          url: payload.file,
        })
      );
    } else {
      // extension payload for getting resigned s3 url and name
      const getImagesUrlPayload = {
        filles: [{ extension: payload.file.name.split(".")[1] }],
      };
      // call api for getting presigned url
      const imagesS3Url = await getCarrierWebsiteImageUploadUrls(
        getImagesUrlPayload
      );
      await uploadFileToS3(imagesS3Url[0].url, payload.file);
     
      $(`[section-id=${payload.id}]`).find('.--image').attr(
        "src",
        imagesS3Url[0].url.split("?")[0]
      );

      dispatch(
        uploadImageSuccess({
          id: payload.id,
          url: imagesS3Url[0].url.split("?")[0],
        })
      );
      dispatch(setTemplateDataToHtml());
    }

  } catch {
    dispatch(uploadImageFailure());
  }
};

export const replaceExistingImages = () => async (dispatch, getState) => {
  try {
    const state = getState();
    if (state.images.replaceImages.length > 0) {
      state.images.replaceImages.forEach((item) => {
        if ($(`[data-image-id=${item.id}]`).attr("src")) {
          $(`[data-image-id=${item.id}]`).attr("src", item.url);
        }
      });
    }
  } catch {
    dispatch(uploadImageFailure());
  }
};


export const getImagesUrls = (payload) => async (dispatch, getState) => {
  try {
     const getImagesUrlPayload = {
      filles: [{ extension: payload.file.name.split(".")[1] }],
    };
    const imagesS3Url = await getCarrierWebsiteImageUploadUrls(
      getImagesUrlPayload
    );
    return imagesS3Url
  } catch {
    dispatch(uploadImageFailure());
  }
};