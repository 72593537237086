import React from 'react';
import './index.scss'; // You can style this component in a CSS file

const StepIndicator = ({ activeStep }) => {
  return (
    <div className="step-indicator" style={{ width: '50%' }}>
      <div className={`step-line ${activeStep >= 1 ? 'active' : ''}`}></div>
      <div className={`step-line ${activeStep >= 2 ? 'active' : ''}`}></div>
      <div className={`step-line ${activeStep >= 3 ? 'active' : ''}`}></div>
    </div>
  );
};

export default StepIndicator;
