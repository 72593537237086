import {
  SET_USER_DATA,
  UPDATE_USER_STATUS,
  SET_TOKEN,
} from "../contents/actionsTypes";

const initialState = {
  user: null,
  token: null,
  isLoggedIn: false,
};

const userReducers = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case UPDATE_USER_STATUS: {
      const {
        payload: { user: userObj },
      } = action;
      return {
        ...state,
        ...userObj,
      };
    }
    // case USER_LOGOUT: {
    //   return {
    //     isLoggedIn: false,
    //   };
    // }
    case SET_TOKEN:
      return {
        ...state,
        token: payload,
        isLoggedIn: true,
      };
    case SET_USER_DATA:
      return {
        ...state,
        user: payload,
        isLoggedIn: true,
      };
    default:
      return state;
  }
};

export default userReducers;
