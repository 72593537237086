import React from "react";
import "./index.css";

function ButtonOutline({ title, sx, className, handleClick, type }) {
  return (
    <button
      type={type}
      className={`button button-outline ${className}`}
      style={sx}
      onClick={handleClick}
    >
      {title}
    </button>
  );
}

export default ButtonOutline;
