import ApiClient from './apiClient';

function client() {
	const api = new ApiClient();

	return {
		apiClient: api
	};
}

export default client;
