/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
import React, { useEffect, useState } from "react";
import $ from "jquery";
import "./index.scss";
import { AiOutlineClose } from "react-icons/ai";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import Publish from "./components/Publish";
import { connect } from "react-redux";
import ImageEditor from "./components/ImageEditor";
import TextEditor from "./components/TextEditor";
import LinkEditor from "./components/linkEditor";
import { GiLargePaintBrush } from "react-icons/gi";
import { TbLetterA } from "react-icons/tb";
import { BiImage, BiLinkAlt } from "react-icons/bi";
import PropertiesPanel from "./components/PropertiesPanel";
import Menu from "./components/Menu";
import { useLocation, useParams } from "react-router";
import {
  deleteTemplateElement,
  elementLastProperty,
  getTemplateContent,
  toggleTemplateSection,
  setComponentTabsData,
  setCurrentElementProperty,
  setEquipmentTypes,
  setTemplateDataToHtml,
  setWebpages,
} from "../../stores/actions/templateActions";
import useQuery from "../../hooks/useQuery";
import { useSearchParams } from "react-router-dom";
import { setBearerToken } from "../../stores/actions/userActions";
// import dragAndDrop from "../../utils/dragAndDrop";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { RiFileAddFill } from "react-icons/ri";
import Button from "@mui/material/Button";
import { IconButton, Tooltip } from "@mui/material";
import config from "../../config";
import imagePlaceholder from "../../assets/images/image-placeholder.svg";
import MyEditor from "../../pages/template/blog/components/MyEditor";
import EquipmentTypes from "./components/EquipmentTypes/Index";
import { appConstants } from "../../constants/constants";
import { toast } from "react-toastify";
import NavLinks from "./components/NavLinks";

const menu = [
  {
    id: 1,
    panel: "text",
    icon: <TbLetterA color="#fff" size={30} />,
  },
  {
    id: 2,
    panel: "image",
    icon: <BiImage color="#fff" size={30} />,
  },
  {
    id: 3,
    panel: "paint",
    icon: <GiLargePaintBrush color="#fff" size={30} />,
  },
  {
    id: 4,
    panel: "link",
    icon: <BiLinkAlt color="#fff" size={30} />,
  },
];

const WebsiteBuilder = ({
  children,
  accessLevel,
  setTemplateDataToHtml,
  getTemplateContent,
  toggleTemplateSection,
  deleteTemplateElement,
  templatePayload,
  template,
  user,
  setBearerToken,
  setComponentTabsData,
  handleCurrentElementProperties,
  elementLastProperty,
  setAllEquipmentTypes,
  setAllWebpages,
}) => {
  const [text, setText] = useState(); // set new text
  const [toggleWebsiteBuilder, setToggleWebsiteBuilder] = useState(false); // toggle sidebar
  const [websiteBuilderPosition, setWebsiteBuilderPosition] =
    useState("sidebar-left"); // handle sidebar position
  const [publish, setPublish] = useState(false); // toggle publush form
  const [isEditBlog, setEditBlog] = useState(false);
  const [currentImage, setCurrentImage] = useState(); // set existing image
  const [panelActive, setPanelActive] = useState("text"); // handle panel active
  const [editor, setEditor] = useState(); // handle editor
  let { businessURL } = useParams(); // fetch businessUrl from route
  const location = useLocation(); // get route location from react router
  const query = useQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const [uniqueId, setUniqueId] = useState(0);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [webpagesState, setLocalWebpagesState] = useState([]);
  const [doneBtnDisable, setDoneBtnDisable] = useState(false);
  const templateData =
    templatePayload &&
    templatePayload.content &&
    templatePayload.content.length;
  const token = user.token && localStorage.getItem("access_token");
  const isLogo = $(".component.active").find(".--text").attr("data-logo");
  const isNumber = $(".component.active").find(".--text").attr("data-number");
  const handleBack = () => {
    setEditBlog(false);
  };
  // const checkTruckpediaUrl =
  //   window.location.hostname !== config.TRUCKPEDIA_ORIGIN;
  // TODO: what is this variable? I don't understand. It is highly possible to be wrong or we can remove it.
  // TODO: remove early access check.
  // TODO: go to incognito mode, log in ezpapel, go to setting, click website, you will not be able to edit it! You have to refresh the page to edit it.

  const isCurrentTruckpediaUrlIsProdOrNot =
    window.location.hostname !== config.TRUCKPEDIA_ORIGIN;
  let checkCarrierForWebsiteBuilder =
    window.location.hostname.split(".")[0] === "carriers";
  if (config.IS_PROD) {
    checkCarrierForWebsiteBuilder =
      window.location.hostname.replace(
        config.TRUCKPEDIA_ORIGIN.replace("carriers", ""),
        ""
      ) === "carriers";
  } else {
    checkCarrierForWebsiteBuilder |=
      window.location.hostname === config.TRUCKPEDIA_ORIGIN;
  }
  const customId = "ba10d736-74ed-42fe-aaa9";
  // const earlyAccess =
  //   user &&
  //   user.user &&
  //   user.user.admin_company_details &&
  //   user.user.admin_company_details.early_access;
  useEffect(() => {
    if (query.get("token")) {
      const accessToken = query.get("token").replaceAll("'", "");
      localStorage.setItem("access_token", accessToken);
      setBearerToken(accessToken);
      if (searchParams.get("token")) {
        searchParams.delete("token");
        setSearchParams(searchParams);
      }
    }
  }, [query]);
  useEffect(() => {
    setComponentIds();
    setComponentContent();
    /*if (token) {
      componentScript(); // set component script
      // dragAndDrop(); //add drag and drop script to html template
      console.log("line 128");
    }*/
    /*if (!isCurrentTruckpediaUrlIsProdOrNot && checkCarrierForWebsiteBuilder) {
      console.log("line 132");
      if (token) {
        componentScript(); // set component script
        console.log("line 135");
        // dragAndDrop(); //add drag and drop script to html template
      }
    } else {*/
    /*
      www.truckpedia.io
      zbu.truckpedia.io
      www.abc.com
      ww2.def.com
    */
    if (!templateData) {
      if (config.IS_PROD) {
        // zbu.truckpedia.io
        if (
          window.location.hostname.split(".").length === 3 &&
          window.location.hostname.includes(
            config.TRUCKPEDIA_ORIGIN.replace("carriers", "")
          )
        ) {
          const subdomain = window.location.hostname.replace(
            config.TRUCKPEDIA_ORIGIN.replace("carriers", ""),
            ""
          );
          if (subdomain === "carriers") {
            componentScript(); // set component script
          } else {
            getTemplateContent({
              businessUrl: subdomain,
            });
          }
        } else {
          // www.abc.com ww2.def.com
          getTemplateContent({ customDomain: window.location.hostname });
        }
      } else {
        if (
          window.location.hostname.split(".").length === 2 &&
          window.location.hostname.split(".")[1] === config.TRUCKPEDIA_ORIGIN
        ) {
          if (window.location.hostname.split(".")[0] === "carriers") {
            componentScript(); // set component script
          } else {
            getTemplateContent({
              businessUrl: window.location.hostname.split(".")[0],
            });
          }
        } else if (window.location.hostname === config.TRUCKPEDIA_ORIGIN) {
          componentScript();
        } else {
          getTemplateContent({ customDomain: window.location.hostname });
        }
      }
    }
    //}
    // eslint-disable-next-line
  }, [children, location, token, isCurrentTruckpediaUrlIsProdOrNot]);

  useEffect(() => {
    if (token) {
      componentScript();
    }
  }, [token]);

  useEffect(() => {
    if (businessURL) {
      if (!templateData) {
        getTemplateContent({ businessUrl: businessURL });
      }
      setComponentContent();
    }
  }, [location, templateData]);

  // handle template data payload
  useEffect(() => {
    setTemplateDataToHtml();
    componentScript();
  }, [text, location]);

  // useEffect(() => {
  //   const delay = 1000;

  //   const timerId = setTimeout(() => {
  //     setComponentTabsData();
  //   }, delay);

  //   return () => clearTimeout(timerId);
  // }, []);

  //  useEffect(() => {
  //  $('.editable-container').change((event) => {
  //   console.log(event)
  //  })
  //  },[])
  // useEffect(() => {
  //   if (!businessURL || token ) {
  //     const unloadCallback = (event) => {
  //       event.preventDefault();
  //       event.returnValue = "";
  //       return "";
  //     };

  //     window.addEventListener("beforeunload", unloadCallback);
  //     return () => window.removeEventListener("beforeunload", unloadCallback);
  //   }
  // }, []);

  //  Set api html response to html
  const setComponentContent = () => {
    if (templateData) {
      setComponentTabsData();
      if (token) {
        componentScript();
      }
    }
  };

  // Use this funtion for set element unique ids
  const setComponentIds = () => {
    const componentId = $("#template").data("component-id");
    let generateUniqueId = 1;
    // set unique id to every editble component
    $("#template-navbar .component").each((index, element) => {
      $(element).attr("section-id", `header-${customId}-${generateUniqueId++}`);
      $(element)
        .find(".--text")
        .attr("block-id", `header-${customId}-${generateUniqueId++}`);
      $(element)
        .find(".--image")
        .attr("block-id", `header-${customId}-${generateUniqueId++}`);
    });

    $("#template .component").each((index, element) => {
      if (!$(element).data("duplicate-id")) {
        $(element).attr(
          "section-id",
          `template-${componentId}-${customId}-${generateUniqueId++}`
        );
        $(element)
          .find(".--text")
          .attr(
            "block-id",
            `template-${componentId}-${customId}-${generateUniqueId++}`
          );
        $(element)
          .find(".--image")
          .attr(
            "block-id",
            `template-${componentId}-${customId}-${generateUniqueId++}`
          );
        // setUniqueId(generateUniqueId);
        setUniqueComponentId();
      }
    });
    $("#template-footer .component").each((index, element) => {
      $(element).attr("custom-id", `footer-${customId}-${generateUniqueId++}`);
      $(element).attr("section-id", `footer-${customId}-${generateUniqueId++}`);
      $(element)
        .find(".--text")
        .attr("block-id", `footer-${customId}-${generateUniqueId++}`);
      $(element)
        .find(".--image")
        .attr("block-id", `footer-${customId}-${generateUniqueId++}`);
    });

    // For sections
    $("#template .--section").each((index, element) => {
      const generatedId = `section-${componentId}-${customId}-${generateUniqueId++}`;
      $(element).attr("parent-section-id", generatedId);
      if (!$(element).hasClass("fixed") && isEditable()) {
        $(element).addClass('highlight');
        $(element).prepend(
          `<span class='toggle-section' data-toggle-id='${generatedId}'>${
            $(element).hasClass('hidden') ? 'Show' : 'Hide'
          }</span>`
        );
      }
    });
    addEventListenerOnToggleSection(); //adding eventlisterners of all deleted-button
  };

  useEffect(() => {
    $(".card-component").each((i, ele) => {
      if (
        $(ele).find(".--image").length === 0 &&
        $(ele).find(".--text").length === 0
      ) {
        $(ele).remove();
      }
    });
  });

  const addEventListenerOnToggleSection = () => {
    const elements = document.getElementsByClassName('toggle-section');
    if (elements.length) {
      for (const element of elements) {
        element.addEventListener('click', handleToggleSection);
      }
    }
  };

  const isEditable = () => {
    if (user.token || checkCarrierForWebsiteBuilder) {
      if (accessLevel !== null || !accessLevel) {
        return true;
      }
    }
    return false;
  };

  const setUniqueComponentId = () => {
    const allBlockIdUniqueNumber = [];

    const sections = $("#template .--section");
    const components = $("#template .component");
    const elements = $.merge(components, sections); //merging sections with components

    $("#template .component").each(function () {
      elements.each(function () {
        const blockId = $(this).attr("block-id");
        if (blockId !== undefined) {
          const lastUniqueId = blockId.split("-");
          const lastNumber = lastUniqueId[lastUniqueId.length - 1];
          if (!isNaN(lastNumber)) {
            allBlockIdUniqueNumber.push(lastNumber);
          }
        }
      });
    });

    // Removing slashs '/' from all existing block IDs
    const convertToNumber = allBlockIdUniqueNumber.map((item) =>
      item.includes("/") ? item.split("/")[0] : item
    );
    const result = Math.max(...convertToNumber);
    setUniqueId(result);
  };
  useEffect(() => {
    setUniqueComponentId();
    handleEquipmentTypes();
    componentScript();
  }, [children, templateData, equipmentTypes]);

  const handleEquipmentTypes = (updatedTypes) => {
    if (updatedTypes && updatedTypes.length > 0) {
      setAllEquipmentTypes(updatedTypes);
      setEquipmentTypes(updatedTypes);
    } else {
      // This code is related to hide/show Equipment Types
      const hasEquipmentTypes = templatePayload.content.some((item) =>
        item.hasOwnProperty("equipmentTypes")
      );
      if (hasEquipmentTypes) {
        const getAllEquipmentTypes = templatePayload.content.filter(
          (item) => item.equipmentTypes
        );
        const [result] = getAllEquipmentTypes;
        setAllEquipmentTypes(result.equipmentTypes);
        setEquipmentTypes(result.equipmentTypes);
      } else {
        const allTypes = appConstants.equipmentTypes.map((item) => item.value);
        setAllEquipmentTypes(allTypes);
        setEquipmentTypes(allTypes);
      }
    }
  };

  const handleNavLinks = (updatedTypes) => {
    if (updatedTypes && updatedTypes.length > 0) {
      setAllWebpages(updatedTypes);
      setLocalWebpagesState(updatedTypes);
    } else {
      // This code is related to hide/show Equipment Types
      const hasWebpages = templatePayload.content.some((item) =>
        item.hasOwnProperty("webpages")
      );
      if (hasWebpages) {
        const getAllWebpages = templatePayload.content.filter(
          (item) => item.webpages
        );
        const [result] = getAllWebpages;
        // setAllWebpages(updatedTypes);
        setAllWebpages(result.webpages);
        setLocalWebpagesState([]);
      }
    }
  };

  // set drag and drop script and set color set font size etc...
  const componentScript = () => {
    // setComponentIds();
    // Add dynamic unique images id

    // set/get active editable element
    if (user.token || checkCarrierForWebsiteBuilder) {
      if (accessLevel !== null || !accessLevel) {
        document.querySelectorAll(".component").forEach((item) => {
          item.addEventListener("click", (event) => {
            event.preventDefault();
            handleEditComponent(item, event);
            // End Image to website builder
          });
        });

        document
          .querySelectorAll(".equipment-type-component")
          .forEach((item) => {
            item.addEventListener("click", (event) => {
              event.preventDefault();
              handleEditComponent(item, event);
            });
          });
        document.querySelectorAll(".nav-edit-button").forEach((item) => {
          item.addEventListener("click", (event) => {
            event.preventDefault();
            handleEditComponent("nav-edit-button", event);
          });
        });
        // document.querySelectorAll(".card-component").forEach((item) => {
        //   item.addEventListener("click", (event) => {
        //     event.preventDefault();
        //     editCardComponent(item, event);
        //     // End Image to website builder
        //   });
        // });
      }
    }
  };

  // const editCardComponent = (item) => {
  //   setToggleWebsiteBuilder(true); // open website builder
  //   setPublish(false); // hide publish from builder
  //   $(".card-component").removeClass("active");
  //   $(".component").removeClass("active");
  //   $(item).addClass("active");
  // };

  const handleEditComponent = (item, event) => {
    setToggleWebsiteBuilder(true); // open website builder
    setPublish(false); // hide publish from builder
    $(".component").removeClass("active"); // remove last active element
    $(item).addClass("active"); // set active to currect element

    if (item === "nav-edit-button") {
      setPanelActive("webpages");
      return;
    }

    if ($(item).hasClass("equipment-type-component")) {
      setPanelActive("equipmentTypes");
      return;
    }

    if ($(event.target).hasClass("--bg-image")) {
      setPanelActive("image");
      setCurrentImage($(".active").find(".--image").attr("src"));
      const properties = $(".active").find(".--image").css(["width", "height"]);
      handleCurrentElementProperties(properties);
      return;
    }

    // Set Text to website builder
    if (event.target.innerText) {
      setPanelActive("text");
      setText(event.target.innerText);
      // $(".card-component").removeClass("active");
      // $(item).addClass("active");
      setTemplateDataToHtml();
      const properties = $(".active")
        .find(".--text")
        .css([
          "font-size",
          "color",
          "text-align",
          "text-transform",
          "font-weight",
          "margin",
          "padding",
          "line-height",
          "letter-spacing",
        ]);
      handleCurrentElementProperties({
        ...properties,
        href: $(".active").find(".--text").attr("href"),
        target: $(".active").find(".--text").attr("target"),
      });
    }
    // End Text to website builder

    // Set Image to website builder

    if (event.target.src) {
      setPanelActive("image");
      setCurrentImage(event.target.src);
      const properties = $(".active").find(".--image").css(["width", "height"]);
      elementLastProperty(properties);
      handleCurrentElementProperties(properties);
    }
    setTemplateDataToHtml();
  };

  // handle replace image
  const handleReplaceImage = (file) => {
    $(".active").find(".--image").attr("src", file);
    setTemplateDataToHtml();
  };

  // close sidebar
  const closeWesbiteBuilder = () => {
    if (publish) {
      setPublish(false);
      if (!text) {
        setToggleWebsiteBuilder(false);
      }
    } else {
      setToggleWebsiteBuilder(false);
    }
  };

  // add text to the end of template
  const handleAddText = () => {
    const componentId = $("#template").data("component-id");
    $(".component").removeClass("active");
    $(".--section").removeClass("active");
    const uniqueIdText = uniqueId + 1;
    const blockId = uniqueIdText + 1;
    const insertText = `<div class='component active' draggable='true' data-new-text="true" section-id=${`template-${componentId}-${customId}-${uniqueIdText}`} ><div class='--text' block-id=${`template-${componentId}-${customId}-${blockId}`}>Text</div></div>`;
    setUniqueId(blockId);
    $(`#template[data-component-id=${componentId}] .--inner`).append(
      insertText
    );
    componentScript();
    // dragAndDrop();
    setToggleWebsiteBuilder(false);
    $("html, body").animate(
      { scrollTop: $("#template-footer").offset().top },
      400
    );
  };

  const handleAddImage = () => {
    const componentId = $("#template").data("component-id");
    $(".component").removeClass("active");
    $(".--section").removeClass("active");
    const uniqueImageId = uniqueId + 1;
    const blockId = uniqueImageId + 1;
    if (isNaN(uniqueImageId) || isNaN(blockId)) {
      setUniqueComponentId();
    }
    const insertImage = `<div class='component active' draggable='true' data-new-image="true" section-id=${`template-${componentId}-${customId}-${uniqueImageId}`}><img src=${imagePlaceholder} class='--image img-placeholder' block-id=${`template-${componentId}-${customId}-${blockId}`}></div>`;
    setUniqueId(blockId);
    $(`#template[data-component-id=${componentId}] .--inner`).append(
      insertImage
    );
    componentScript();
    // dragAndDrop();
    setToggleWebsiteBuilder(false);
    $("html, body").animate(
      { scrollTop: $("#template-footer").offset().top },
      400
    );
  };

  const handleAddBlog = () => {
    setEditBlog(true);
  };

  const Editor = ({ panel, icon, handleClick }) => {
    let active = "";
    if (editor === panel) {
      active = "active";
    }
    if (!editor && panelActive === panel) {
      active = "active";
    }
    return (
      <div className={`prop-role ${active}`} onClick={handleClick}>
        {icon}
      </div>
    );
  };

   // Toggle Section
   const handleToggleSection = (event) => {
    const id = $(event.target).data('toggle-id');
    toggleTemplateSection(id);
  };

  // handle editor active
  const handleActiveEditor = (panel) => {
    setEditor(panel);
  };

  // handle panle active
  const handleActivePanel = (panel) => {
    setPanelActive(panel);
    setEditor("");
  };

  const removeActiveClass = () => {
    $(".component").each((i, ele) => {
      $(ele).removeClass("active");
    });
    componentScript();
  };

  // Delete element
  const handleDeleteElement = () => {
    setToggleWebsiteBuilder(false);
    const selectedSectionId = $(".component.active").attr("section-id");
    deleteTemplateElement(selectedSectionId);
  };

  // Add new element
  const handleAddElement = () => {
    const componentId = $("#template").data("component-id");
    const nodeName = $(".component.active").parent()[0]?.nodeName;
    setUniqueComponentId();
    if (nodeName && nodeName === "LI") {
      const cloneElement = $(".component.active").parents("li").clone();
      $(".component.active")
        .parents("ul")
        .append(`<li>${cloneElement.html()}</li>`);
      removeActiveClass();
    } else {
      if ($(".card-component").hasClass("active")) {
        const cloneElement = $(".card-component.active").clone();
        $(".card-component.active")
          .parent()
          .append(
            `<div class="card-component" draggable="true">${cloneElement.html()}`
          );
        removeActiveClass();
      } else {
        // Duplicate new text
        const selectedSectionId = $(".component.active").attr("section-id");

        const cloneElement = $(".component.active")
          .clone()
          .removeAttr("section-id")
          .attr(
            "section-id",
            `template-${componentId}-${customId}-${uniqueId + 1}`
          )
          .attr("data-duplicate-id", selectedSectionId);

        // Update block-id for any '.--text' elements within the cloned element
        if ($(cloneElement).find(".--text")) {
          $(cloneElement)
            .find(".--text")
            .attr(
              "block-id",
              `template-${componentId}-${customId}-${uniqueId + 2}`
            );
        }

        // Update block-id for any '.--image' elements within the cloned element
        if ($(cloneElement).find(".--image")) {
          $(cloneElement)
            .find(".--image")
            .attr(
              "block-id",
              `template-${componentId}-${customId}-${uniqueId + 2}`
            );
        }

        // Insert cloned element as the next sibling of the active component
        $(".component.active").after(cloneElement);
        let uniqueIdIncrement = uniqueId + 3;
        setUniqueId(uniqueIdIncrement);

        removeActiveClass();
        $(cloneElement).addClass("active");
        setTemplateDataToHtml();
      }
    }
  };

  const handlePublish = () => {
    if (!doneBtnDisable) {
      setPublish(true);
      setToggleWebsiteBuilder(true);
    } else {
      toast.error("Please fix the value");
    }
  };

  const handleTemplateDataToHtml = () => {
    setTemplateDataToHtml();
  };

  const getValidity = (value) => {
    setDoneBtnDisable(value);
  };

  // setComponentContent();
  useEffect(() => {
    // eslint-disable-next-line
    if (query.get("showRegistration") == 1) {
      handlePublish();
    }
  }, [query]);

  return isEditBlog ? (
    <MyEditor onBack={handleBack} navigateToBlogs={handleBack} blogId={0} />
  ) : (
    <div>
      {!accessLevel && toggleWebsiteBuilder ? (
        <div>
          <div
            className={`editable-sidebar shadow-lg ${websiteBuilderPosition}`}
          >
            <div>
              <div className="text-center p-3">
                <img
                  src={require("../../assets/images/truckpedia/logo-sidebar.png")}
                  alt="logo"
                />
              </div>
              <div className="properties-panel">
                {panelActive !== "equipmentTypes" &&
                  menu.map((item) => {
                    if (publish) return;
                    if (item.panel === "paint") {
                      return (
                        <Editor
                          key={item.id}
                          panel={item.panel}
                          icon={item.icon}
                          handleClick={() => handleActiveEditor(item.panel)}
                        />
                      );
                    }
                    if (
                      (panelActive === "text" && item.panel === "image") ||
                      (panelActive === "image" &&
                        (item.panel === "text" || item.panel === "link")) ||
                      (panelActive === "link" && item.panel === "image")
                    )
                      return;
                    return (
                      <Editor
                        key={item.id}
                        panel={item.panel}
                        icon={item.icon}
                        handleClick={() => handleActivePanel(item.panel)}
                      />
                    );
                  })}
              </div>
              <div className="text-end p-3">
                <div className="pointer mb-3" onClick={closeWesbiteBuilder}>
                  <AiOutlineClose color="#fff" size={18} />
                </div>

                <IoIosArrowBack
                  color="#fff"
                  size={18}
                  className="pointer"
                  onClick={() => setWebsiteBuilderPosition("sidebar-left")}
                />
                <IoIosArrowForward
                  color="#fff"
                  size={18}
                  className="pointer"
                  onClick={() => setWebsiteBuilderPosition("sidebar-right")}
                />
              </div>
            </div>
            <div
              className="p-3"
              style={{ height: publish ? "80vh" : "65vh", overflow: "auto" }}
            >
              {!publish && editor === "paint" ? (
                <PropertiesPanel
                  panel={panelActive}
                  setTemplateDataToHtml={handleTemplateDataToHtml}
                />
              ) : null}

              {!publish && !editor && panelActive === "text" ? (
                <TextEditor
                  edtiorText={text}
                  isNumber={isNumber}
                  setTemplateDataToHtml={handleTemplateDataToHtml}
                  getValidity={getValidity}
                  min={0}
                  max={100}
                />
              ) : !publish && !editor && panelActive === "image" ? (
                <ImageEditor
                  handleReplaceImage={handleReplaceImage}
                  exitingImage={currentImage}
                  setTemplateDataToHtml={handleTemplateDataToHtml}
                />
              ) : !publish && !editor && panelActive === "link" ? (
                <LinkEditor setTemplateDataToHtml={handleTemplateDataToHtml} />
              ) : null}

              {!publish && !editor && panelActive === "equipmentTypes" && (
                <EquipmentTypes
                  types={equipmentTypes}
                  handleEquipmentTypes={handleEquipmentTypes}
                />
              )}
              {!publish && !editor && panelActive === "webpages" && (
                <NavLinks
                  selectedPages={webpagesState}
                  handleNavLinks={handleNavLinks}
                  setLocalWebpages={setLocalWebpagesState}
                />
              )}
              {publish && <Publish publishEditTemplate={text} token={token} />}
            </div>
            {!publish ? (
              <div className="panel-footer">
                {/* {panelActive !== "equipmentTypes" && !isLogo && (
                  <Tooltip title="Add this element">
                    <IconButton
                      aria-label="add"
                      color="primary"
                      onClick={() => handleAddElement()}>
                      <RiFileAddFill color="#fff" />
                    </IconButton>
                  </Tooltip>
                )} */}
                {panelActive !== "equipmentTypes" && (
                  <div>
                    <Tooltip title="Delete this element">
                      <IconButton
                        aria-label="delete"
                        color="primary"
                        onClick={handleDeleteElement}
                      >
                        <RiDeleteBin5Fill color="#fff" />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}

                <Button
                  size="large"
                  variant="contained"
                  className={`done-btn ${doneBtnDisable && "disabled"}`}
                  onClick={() => setToggleWebsiteBuilder(false)}
                >
                  Done
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {/* Publish button  */}

      {/* {(!isCurrentTruckpediaUrlIsProdOrNot && !businessURL) ||
      user.token ? (
        <div
          className={`panel ${
            websiteBuilderPosition === "sidebar-left"
              ? "panel-right"
              : "panel-left"
          }`}
        >
          <Menu
            handleAddText={handleAddText}
            handlePublish={handlePublish}
            handleAddImage={handleAddImage}
          />
        </div>
      ) : null} */}

      {user.token || checkCarrierForWebsiteBuilder ? (
        <div
          className={`panel ${
            websiteBuilderPosition === "sidebar-left"
              ? "panel-right"
              : "panel-left"
          }`}
        >
          <Menu
            handleAddText={handleAddText}
            handlePublish={handlePublish}
            handleAddImage={handleAddImage}
            handleAddBlog={handleAddBlog}
            invalid={doneBtnDisable}
          />
        </div>
      ) : null}

      <div id="template-content">{children}</div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  accessLevel: state.accessLevel.level,
  templatePayload: state.template.templatePayload,
  template: state.template,
  user: state.user,
  imagesUrl: state.images,
});

const mapStateToDispatch = (dispatch) => ({
  setTemplateDataToHtml: () => dispatch(setTemplateDataToHtml()),
  getTemplateContent: (businessUrl) =>
    dispatch(getTemplateContent(businessUrl)),
  setBearerToken: (token) => dispatch(setBearerToken(token)),
  setComponentTabsData: () => dispatch(setComponentTabsData()),
  deleteTemplateElement: (deleteId) =>
    dispatch(deleteTemplateElement(deleteId)),
  toggleTemplateSection: (toggleId) =>
    dispatch(toggleTemplateSection(toggleId)),
  handleCurrentElementProperties: (ele) =>
    dispatch(setCurrentElementProperty(ele)),
  elementLastProperty: (data) => dispatch(elementLastProperty(data)),
  setAllEquipmentTypes: (data) => dispatch(setEquipmentTypes(data)),
  setAllWebpages: (data) => dispatch(setWebpages(data)),
});

export default connect(mapStateToProps, mapStateToDispatch)(WebsiteBuilder);
