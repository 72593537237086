import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import "./index.scss";
const Loader = ({ processingText, fullPage, textColor }) => {
  return (
    <div>
      <Box className={["loader-container", fullPage ? "full-page" : null]}>
        {processingText ? (
          <div className="processing-text" style={textColor}>{processingText}</div>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </div>
  );
};

export default Loader;

Loader.propTypes = {
  processingText: PropTypes.string,
  fullPage: PropTypes.bool,
};

Loader.defaultProps = {
  processingText: null,
  fullPage: false,
};
