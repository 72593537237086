export const SET_ACCESS_LEVEL = "SET_ACCESS_LEVEL";
export const SET_USER_DATA = "SET_USER_DATA";
export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS";
export const SET_TEMPLATE = "SET_TEMPLATE";
export const SET_PUBLISH = "SET_PUBLISH";
export const FETCH_PUBLISH = "FETCH_PUBLISH";
export const FETCH_PUBLISH_SUCCESS = "FETCH_PUBLISH_SUCCESS";
export const FETCH_PUBLISH_FAILURE = "FETCH_PUBLISH_FAILURE";
export const FETCH_TEMPLATE_CONTENT = "FETCH_TEMPLATE_CONTENT";
export const FETCH_TEMPLATE_SUCCESS = "FETCH_TEMPLATE_SUCCESS";
export const FETCH_TEMPLATE_FAILURE = "FETCH_TEMPLATE_FAILURE";
export const SET_TOKEN = "SET_TOKEN";
export const SET_TEAMPLATE_SUCCESS = "SET_TEAMPLATE_SUCCESS";
export const UPDATE_TAB_DATA = "UPDATE_TAB_DATA";
export const FETCH_IMAGE = "FETCH_IMAGE";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE";
export const SET_CURRENT_ELEMENT_PROPERTIES = "SET_CURRENT_ELEMENT_PROPERTIES";
export const SET_ELEMENT_LAST_PROPERTIES = "SET_ELEMENT_LAST_PROPERTIES";
export const FETCH_TEMPLATE_CONTENT_LOADING = "FETCH_TEMPLATE_CONTENT_LOADING";
export const FETCH_TEMPLATE_CONTENT_LOADING_STOP =
  "FETCH_TEMPLATE_CONTENT_LOADING_STOP";
export const AVAILABLE_TRUCKS = "AVAILABLE_TRUCKS";
export const RESERVE_TRUCKS = "RESERVE_TRUCKS";
export const ERROR = "ERROR";
export const SET_AVAILABLE_TRUCKS_STATUS = "SET_AVAILABLE_TRUCKS_STATUS";
export const SEARCH_QUOTES_PAYLOAD = "SEARCH_QUOTES_PAYLOAD";
export const CLEAR_QUOTES_PAYLOAD = "CLEAR_QUOTES_PAYLOAD";
export const SET_TEAMPLATE_PAYLOAD = "SET_TEAMPLATE_PAYLOAD";
export const CLEAR_QUOTES_DATA = "CLEAR_QUOTES_DATA";
export const CLEAR_BLOG_DATA = "CLEAR_BLOG_DATA";
export const SET_EQUIPMENT_TYPES_PAYLOAD = "SET_EQUIPMENT_TYPES_PAYLOAD";
export const SET_WEBPAGES_PAYLOAD = "SET_WEBPAGES_TYPES_PAYLOAD";
