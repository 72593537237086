import React, { useEffect } from "react";
import { setPlace } from "../../../helpers";

const GoogleMapsApi = (props) => {
  // convert distance to miles
  const convertToMiles = (distanceString) => {
    if (distanceString.includes("ft")) {
      return (
        parseFloat(distanceString.replace(/[, ft]+/g, "")) * 0.000189394
      ).toFixed(2);
    } else if (distanceString.includes("km")) {
      return (
        parseFloat(distanceString.replace(/[, km]+/g, "")) * 0.621371
      ).toFixed(2);
    } else if (distanceString.includes("mi")) {
      return distanceString.replace(/[, mi]+/g, "");
    } else if (distanceString.includes("m")) {
      return (
        parseFloat(distanceString.replace(/[, m]+/g, "")) * 0.000621371
      ).toFixed(2);
    }
  };
  const {
    origin,
    destination,
    onDistanceChange,
    setPickupLocation,
    setDeliveryLocation,
    distanceLoading,
  } = props;

  useEffect(() => {
    if (window.google?.maps) {
      if (origin && destination) {
        distanceLoading(true);
        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
          {
            origin,
            destination,
            travelMode: "DRIVING",
          },
          (response, status) => {
            if (status === "OK") {
              const leg = response.routes[0].legs[0];
              const distanceString = leg.distance.text;
              const distance = convertToMiles(distanceString);
              onDistanceChange(distance);
            } else {
              console.error("Error fetching directions:", status);
            }
            distanceLoading(false);
          }
        );
      }
    }
  }, [origin, destination, onDistanceChange, distanceLoading]);
  useEffect(() => {
    if (window.google?.maps) {
      const geocoder = new window.google.maps.Geocoder();
      // Geocode the address
      if (origin) {
        geocoder.geocode({ address: `${origin}` }, (results, status) => {
          if (status === "OK" && results.length > 0) {
            const addressData = {};
            const { city, address, state, zipCode } = setPlace(results[0]);
            addressData.city = city;
            addressData.address = address;
            addressData.state = state;
            addressData.zipCode = zipCode;
            setPickupLocation(addressData);
          } else {
            console.error(
              "Geocode was not successful for the following reason:",
              status
            );
          }
        });
      }
      if (destination) {
        geocoder.geocode({ address: `${destination}` }, (results, status) => {
          if (status === "OK" && results.length > 0) {
            const addressData = {};
            const { city, address, state, zipCode } = setPlace(results[0]);
            addressData.city = city;
            addressData.address = address;
            addressData.state = state;
            addressData.zipCode = zipCode;
            setDeliveryLocation(addressData);
          } else {
            console.error(
              "Geocode was not successful for the following reason:",
              status
            );
          }
        });
      }
    }
  }, [
    origin,
    destination,
    setDeliveryLocation,
    setPickupLocation,
    distanceLoading,
  ]);

  return <></>;
};

export default GoogleMapsApi;
