import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Login from "../pages/truckpedia/auth/login";
import SignUp from "../pages/truckpedia/auth/signup";
import HowItWorks from "../pages/truckpedia/howItWorks";
import { getAuthUser } from "../api/auth";
import { useDispatch } from "react-redux";
import { setBearerToken, setUserData } from "../stores/actions/userActions";
import About from "../pages/template/about";
import ContactUs from "../pages/template/contactUs";
import Instant from "../pages/template/instant";
import Tracking from "../pages/template/tracking";
import Blog from "../pages/template/blog";
import MyEditor from "../pages/template/blog/components/MyEditor";
import Truckpedia from "../pages/Truckpedia";
import Main from "../pages/template/Main";
import { replaceExistingImages } from "../stores/actions/imagesActions";
import PostDetails from "../pages/template/blog/components/PostDetails";
export const Routing = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("access_token");
  const location = useLocation();
  useEffect(() => {
    if (token) {
      dispatch(setBearerToken(token));
      getAuthUser().then((data) => {
        dispatch(setUserData(data.payload));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(replaceExistingImages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <Routes>
      <Route element={<Truckpedia />}>
        <Route path="/" element={<HowItWorks />} exact />
        <Route path="/register" element={<SignUp />} exact />
        <Route path="/login" element={<Login />} exact />
      </Route>

      {/* Template  */}
      <Route element={<Main />} exact>
        <Route path="/instant-quote" element={<Instant />} exact />
        <Route path="/about" element={<About />} exact />
        <Route path="/contact-us" element={<ContactUs />} exact />
        <Route path="/tracking" element={<Tracking />} exact />
        <Route path="/blogs" element={<Blog />} exact />
        <Route path="/editor/:id" element={MyEditor} />
        <Route path="/blog/:id" element={<PostDetails />} />
        {/* template dynamic routes  */}
        <Route path="/carrier/:businessURL" element={<Instant />} exact />
        <Route path="/carrier/:businessURL/about" element={<About />} exact />
        <Route
          path="/carrier/:businessURL/contact-us"
          element={<ContactUs />}
          exact
        />
        <Route
          path="/carrier/:businessURL/tracking"
          element={<Tracking />}
          exact
        />
      </Route>
    </Routes>
  );
};
