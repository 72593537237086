import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchBlogPostDetail } from "../../../../stores/actions/templateActions";
import "../index.scss";
import Typography from "@mui/material/Typography";

const PostDetails = ({ title, content }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, blogDetails, error } = useSelector(
    (state) => state.template
  );

  useEffect(() => {
    if (id) {
      dispatch(fetchBlogPostDetail(id));
    }
  }, [dispatch, id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!blogDetails && !title && !content) {
    return <div>Post not found</div>;
  }
  const post = blogDetails?.blog;

  const blogTitle = title || post?.title || "Uncategorized";

  let htmlContent = "";
  if (content) {
    if (typeof content === "object") {
      htmlContent = content?.description;
    } else {
      htmlContent = content;
    }
  } else {
    if (typeof post?.content != "object") {
      const data = JSON.parse(post?.content);
      htmlContent = data?.description;
    } else {
      htmlContent = post?.content?.description;
    }
  }

  return (
    <div>
      <div className="component">
        <div className="blog-wrapper --bg-image">
          <div className="bg-blog-img">
            <img
              src={require("../../../../assets/images/blog_bg.jpg")}
              alt="bg img"
              className="about-bg-img --image"
            />
            <Typography
              variant="h4"
              className="blog-title container truncate-lines"
              bold="true"
            >
              {blogTitle}
            </Typography>
          </div>
        </div>
      </div>
      {/* <div
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="w-100">
          <img
            src={require("../../../../assets/images/blog_bg.jpg")}
            height={200}
            width={"100%"}
            style={{ objectFit: "cover" }}
          />
        </div>

        <Typography
          variant="h4"
          className="blog-title container truncate-lines"
          bold="true"
        >
          {blogTitle}
        </Typography>
      </div> */}

      <Paper elevation={0} className="preview container">
        <Box dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </Paper>
    </div>
  );
};

export default PostDetails;
