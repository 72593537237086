import {
  SET_TEMPLATE,
  FETCH_TEMPLATE_CONTENT,
  FETCH_TEMPLATE_FAILURE,
  SET_TEAMPLATE_SUCCESS,
  SET_CURRENT_ELEMENT_PROPERTIES,
  SET_ELEMENT_LAST_PROPERTIES,
  FETCH_TEMPLATE_CONTENT_LOADING,
  FETCH_TEMPLATE_CONTENT_LOADING_STOP,
  SET_TEAMPLATE_PAYLOAD,
  CLEAR_BLOG_DATA,
  SET_EQUIPMENT_TYPES_PAYLOAD,
  SET_WEBPAGES_PAYLOAD,
} from "../contents/actionsTypes";

const initialState = {
  templatePayload: { content: [] },
  textProperties: {},
  elementLastProperties: null,
  isLoading: false,
  isLoadingContent: false,
  companyData: {},
  loading: false,
  blogs: [],
  blogDetails: null,
  error: null,
  page: 1,
  equipmentTypes: [],
  webpages: [],
};

const templateReducer = (state = initialState, action) => {
  const data = action.payload;
  switch (action.type) {
    case SET_TEMPLATE:
      const removeExistingData = state.templatePayload.content.filter(
        (x) => x.isSection || x.blockId !== data.blockId
      );
      return {
        ...state,
        templatePayload: {
          content: [data, ...removeExistingData],
        },
        isLoading: false,
      };
    case FETCH_TEMPLATE_CONTENT:
      return {
        ...state,
        isLoading: true,
      };

    case SET_TEAMPLATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        templatePayload: data,
      };
    }
    case SET_CURRENT_ELEMENT_PROPERTIES: {
      return {
        ...state,
        textProperties: data,
      };
    }
    case FETCH_TEMPLATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case SET_ELEMENT_LAST_PROPERTIES: {
      return {
        ...state,
        elementLastProperties: data,
      };
    }
    case FETCH_TEMPLATE_CONTENT_LOADING: {
      return {
        ...state,
        isLoadingContent: true,
      };
    }
    case FETCH_TEMPLATE_CONTENT_LOADING_STOP: {
      return {
        ...state,
        isLoadingContent: false,
      };
    }
    case SET_TEAMPLATE_PAYLOAD: {
      return {
        ...state,
        companyData: data,
      };
    }
    case "CREATE_BLOG_POST_SUCCESS":
      return {
        ...state,
        blogPosts: [...state.blogPosts, action.payload],
      };
    case "FETCH_BLOGS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_BLOGS_SUCCESS":
      return {
        ...state,
        loading: false,
        blogs: action.payload,
      };
    case "FETCH_BLOGS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "FETCH_BLOG_DETAILS_REQUEST":
      return {
        ...state,
        loading: true,
        blogDetails: null,
        error: null,
      };
    case "FETCH_BLOG_DETAILS_SUCCESS":
      return {
        ...state,
        loading: false,
        blogDetails: action.payload,
        error: null,
      };
    case "FETCH_BLOG_DETAILS_FAILURE":
      return {
        ...state,
        loading: false,
        blogDetails: null,
        error: action.payload,
      };
    case CLEAR_BLOG_DATA:
      return {
        ...state,
        loading: false,
        blogDetails: null,
        error: action.payload,
      };
    case SET_EQUIPMENT_TYPES_PAYLOAD:
      const existingIndex = state.templatePayload.content.findIndex((item) =>
        item.hasOwnProperty("equipmentTypes")
      );

      if (existingIndex !== -1) {
        return {
          ...state,
          loading: false,
          equipmentTypes: action.payload,
          templatePayload: {
            content: state.templatePayload.content.map((item, index) =>
              index === existingIndex
                ? { ...item, equipmentTypes: action.payload }
                : item
            ),
          },
        };
      } else {
        return {
          ...state,
          loading: false,
          equipmentTypes: action.payload,
          templatePayload: {
            content: [
              ...state.templatePayload.content,
              { equipmentTypes: action.payload },
            ],
          },
        };
      }
    case SET_WEBPAGES_PAYLOAD: {
      const existingIndex = state.templatePayload.content.findIndex((item) =>
        item.hasOwnProperty("webpages")
      );
      if (existingIndex !== -1) {
        return {
          ...state,
          loading: false,
          webpages: action.payload,
          templatePayload: {
            content: state.templatePayload.content.map((item, index) =>
              index === existingIndex
                ? { ...item, webpages: action.payload }
                : item
            ),
          },
        };
      } else {
        return {
          ...state,
          loading: false,
          webpages: action.payload,
          templatePayload: {
            content: [
              ...state.templatePayload.content,
              { webpages: action.payload },
            ],
          },
        };
      }
    }
    default:
      return state;
  }
};

export default templateReducer;
