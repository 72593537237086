import {
  AVAILABLE_TRUCKS,
  RESERVE_TRUCKS,
  SET_AVAILABLE_TRUCKS_STATUS,
  SEARCH_QUOTES_PAYLOAD,
  CLEAR_QUOTES_PAYLOAD,
  CLEAR_QUOTES_DATA
} from "../contents/actionsTypes";

const initialState = {
  trucks: null,
  reserveTrucks: null,
  availableTrucksStatus: true,
  quotesPayload: null,
};

const truckReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case AVAILABLE_TRUCKS: {
      return {
        ...state,
        trucks: payload,
        isLoading: false,
      };
    }
    case RESERVE_TRUCKS: {
      return {
        ...state,
        reserveTrucks: payload,
        isLoading: false,
      };
    }
    case SET_AVAILABLE_TRUCKS_STATUS: {
      return {
        ...state,
        availableTrucksStatus: payload,
      };
    }
    case SEARCH_QUOTES_PAYLOAD: {
      return {
        ...state,
        quotesPayload: payload,
      };
    }
    case CLEAR_QUOTES_PAYLOAD: {
      return {
        ...state,
        trucks: null,
        reserveTrucks: null,
        availableTrucksStatus: true,
      }
    }
    case CLEAR_QUOTES_DATA: {
      return {
        ...state,
        quotesPayload: null,
      } 
    }
    default:
      return state;
  }
};

export default truckReducer;
