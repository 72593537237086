import React from "react";
import "./carouselCard.css";
function CarouselCard({ title, subTitle, desc }) {
  return (
    <div className="d-flex">
      <div className="carousel-card" style={{ width: "18rem" }}>
        <div className="component">
        <img
          src={require("../../../assets/images/hero_banner.png")}
          className="card-img-top --image"
          style={{ borderRadius: 10 }}
          alt="img"
        />
        </div>
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <span style={{ fontSize: 10 }}>{subTitle}</span>
          <p className="card-text" style={{ fontSize: 13 }}>
            {desc}
          </p>
          <div className="card-footer">
          <a href="/" className="btn btn-outline-primary border-0">
            View Case Study
          </a>

          </div>
        </div>
      </div>
    </div>
  );
}

export default CarouselCard;
