import React from "react";
import "../index.scss";
import Input from "../../../../components/ui/Truckpedia/Input/Input";
import ButtonPrimary from "../../../../components/ui/Buttons/ButtonPrimary";
import apple from "../../../../assets/images/truckpedia/auth/apple-logo.svg";
import facebook from "../../../../assets/images/truckpedia/auth/facebook-logo.svg";
import google from "../../../../assets/images/truckpedia/auth/google-logo.svg";
import { Link } from "react-router-dom";
function Login(props) {
  return (
    <div>
      <div className="container">
        <div className="auth-card">
          <div>
            <h2 className="font-weight-bold mb-3">Login</h2>
            <div>
              <Input label="Email address" className="mt-3" />
              <Input label="Password" type="password" className="mt-3" />

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label
                  className="form-check-label small-text-color small fw-light"
                  htmlFor="flexCheckDefault"
                >
                  Remember Me
                </label>
              </div>

              <div>
                <ButtonPrimary title="LOGIN" className="w-100 mt-2" />
                <div className="mt-2 text-center">
                  <span className="fw-light small">
                    Don't have an account?{" "}
                    <Link to="/register">
                      <span className="color-primary">Sign up</span>
                    </Link>
                  </span>
                </div>
              </div>
              <div className="container">
                <div className="d-flex justify-content-evenly mt-4">
                  <span className="fw-light small">Or continue with</span>
                  <div className="auth-icons">
                    <img src={apple} alt="apple-logo" />
                    <img src={facebook} alt="facebook-logo" />
                    <img src={google} alt="google-logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
