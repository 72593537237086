import { registerTruckpedia } from "../../api/auth";
import {
  postTemplateContent,
  // carrierWebsiteUrl,
  getCarrierWebsiteImageUploadUrls,
  uploadFileToS3,
} from "../../api/templateApi";
import {
  FETCH_PUBLISH,
  FETCH_PUBLISH_SUCCESS,
  FETCH_PUBLISH_FAILURE,
  UPDATE_TAB_DATA,
} from "../contents/actionsTypes";
export const fetchPublish = () => ({
  type: FETCH_PUBLISH,
});

export const fetchPublishSuccess = (data) => ({
  type: FETCH_PUBLISH_SUCCESS,
  payload: data,
});

export const fetchPublishFailure = () => ({
  type: FETCH_PUBLISH_FAILURE,
});

export const updateSpecifcTabData = (data, name) => ({
  type: UPDATE_TAB_DATA,
  payload: { data, name },
});

export const publishTemplate =
  (payload, businessURL) => async (dispatch, getState) => {
    const formData = payload;
    // const customDomain = "";
    const customDomain = payload.customDomain;
    delete payload.customDomain;
    try {
      const state = getState();
      dispatch(fetchPublish());
      // register new user to truckpedia
      const token = localStorage.getItem("access_token");
      // Only for new users
      if (!token) {
        const registerResponse = await registerTruckpedia(formData);
        const newUserAccessToken = registerResponse.payload.access_token;
        localStorage.setItem("access_token", newUserAccessToken); // set access token to local storage
      }

      // if (!businessURL && token) {
      //   const payload = {
      //     url: formData.business_url,
      //   };
      //   await carrierWebsiteUrl(payload);
      // }

      if (!token) {
        const stateImages = state.images.replaceImages; // get user deupload redux state images
        if (stateImages.length > 0) {
          const uniqueImages = []; // Array for unique images
          // filter unique images
          stateImages.map((x) =>
            uniqueImages.filter((a) => a.id === x.id).length > 0
              ? null
              : uniqueImages.push(x)
          );
          const images = [];
          if (uniqueImages.length > 0) {
            // extension payload for getting resigned s3 url and name
            const getImagesUrlPayload = {
              filles: uniqueImages.map((ele) => {
                return { extension: ele.url.name.split(".")[1] };
              }),
            };
            // call api for getting presigned url
            const imagesS3Urls = await getCarrierWebsiteImageUploadUrls(
              getImagesUrlPayload
            );
            //  upload multiple images to s3 through presigned url
            for (let i = 0; i < imagesS3Urls.length; i++) {
              await uploadFileToS3(imagesS3Urls[i].url, uniqueImages[i].url);
              // push uploaded image url, file name, id to new array
              images.push({
                id: uniqueImages[i].id,
                fileName: imagesS3Urls[i].fileName,
                url: imagesS3Urls[i].url,
              });
            }
          }
          images.forEach((item) => {
            state.template.templatePayload.content.forEach((x, i) => {
              if (item.id === x.sectionId) {
                state.template.templatePayload.content[i].content =
                  item.url.split("?")[0];
              }
            });
          });
        }
      }

      let businessURLPayload = null;
      if (formData.business_url) {
        businessURLPayload = formData.business_url;
      }

      if (businessURL) {
        businessURLPayload = businessURL;
      }

      // new template content payload
      // const payload = {
      //   // customDomain: customDomain !== "" ? customDomain : null,
      //   businessUrl: businessURLPayload,
      //   websiteHtml: {
      //     ...state.template.templatePayload
      //   },
      // };
      try {
        const jsonCollection = state.template.templatePayload.content.map(
          (item) => {
            if (item.blockId && item.blockId.includes("/")) {
              const blockId = item.blockId.split("/")[0];
              item.blockId = blockId;
            }
            return item;
          }
        );
        const jsonPayload = JSON.stringify(jsonCollection);
        const payload = {
          customDomain: customDomain !== "" ? customDomain : null,
          businessUrl: businessURLPayload,
          websiteContent: jsonPayload,
        };
        if (customDomain === "") {
          delete payload.customDomain;
        }
        if (!businessURLPayload) {
          delete payload.businessUrl;
        }
        // call api to set new html to DB
        postTemplateContent(payload).then((res) => {
          dispatch(fetchPublishSuccess(res));
          if (res.result) {
            const accessToken = localStorage.getItem("access_token");
            if (
            !process.env.NODE_ENV ||
            process.env.NODE_ENV !== "development"
            ) {
            window.location.href = `https://dashboard.truckpedia.io/login?access_token='${accessToken}'`;
            } else {
            window.location.href = `http://127.0.0.1:8000/login?access_token='${accessToken}'`;
            }
          } else {
            new Error("Something went wrong");
          }
        });

        //  const jsonPayload = JSON.stringify(payload.websiteHtml.footer.htmlCode);
        //  payload.websiteContent.footer.htmlCode = jsonPayload;

        //  const jsonPayload = JSON.stringify(payload.websiteHtml.footer.htmlCode);
        //  payload.websiteHtml.footer.htmlCode = jsonPayload;
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      dispatch(fetchPublishFailure());
    }
  };
