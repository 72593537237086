import React from "react";
import TeamCard from "../../../../components/ui/Team/TeamCard";
import dotted from "../../../../assets/images/about/team/dotted.svg";

const team = [
  {
    id: 1,
    name: "Claid Owen",
    designation: "CEO",
  },
  {
    id: 2,
    name: "Gina Williams",
    designation: "Executive Manager",
  },
];

const teamRow = [
  {
    id: 3,
    name: "Ben Possum",
    designation: "Full-time loader",
  },
  {
    id: 4,
    name: "Tansa White",
    designation: "Service Analytic",
  },
  {
    id: 5,
    name: "Mila West",
    designation: "HR Manager",
  },
  {
    id: 6,
    name: "Arnold Regan",
    designation: "Content Videomaker",
  },
];
function Team() {
  return (
    <div>
      <div style={{ background: "#140A33", height: "78%" }}>
        <div>
          <img
            src={require("../../../../assets/images/circles.png")}
            alt="img"
            style={{ height: 300 }}
          />
          <div className="position-relative" style={{ bottom: "90px" }}>
            <div className="container">
              <div className="d-flex gap-5">
                {team.map((item, index) => (
                    <TeamCard
                      key={index}
                      name={item.name}
                      designation={item.designation}
                    />
                ))}
                {/* <TeamCard /> */}
                <div className="color-white mt-5">
                  <div className="component">
                    <span
                      className="--text"
                      style={{ fontSize: 14, color: "#C5BFFF" }}
                    >
                      OUR TEAM
                    </span>
                  </div>
                  <div className="component">
                    <h4
                      className="font-weight-bold h1 color-white --text"
                      style={{ fontSize: 40 }}
                    >
                      The team that support and help you
                    </h4>
                  </div>
                  <div className="component">
                    <p className="mt-3 --text">
                      Australian herring zingel arowana Pacific cod Red chub
                      freshwater hatchetfish plaice; albacore starry flounder
                      kingfish Owens pupfish butterflyfish noodlefish Atlantic
                      eel
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="d-flex flex-wrap gap-5">
                  {teamRow.map((item, index) => (
                    <TeamCard
                      key={index}
                      name={item.name}
                      designation={item.designation}
                    />
                  ))}
                </div>
                <div>
                  <img src={dotted} alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
