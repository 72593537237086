/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { appConstants } from "../../../../constants/constants";
import { Typography } from "@mui/material";
import { setEquipmentTypes } from "../../../../stores/actions/templateActions";

function EquipmentTypes({ types, handleEquipmentTypes }) {
  const [checked, setChecked] = useState([]);
  const [allTypes, setAllTypes] = useState([]);
  const draggedItem = React.useRef(null);
  const overItem = React.useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    setChecked(types);
  }, [types]);

  const handleChange = (event) => {
    const { value } = event.target;
    setChecked((prevChecked) => {
      if (prevChecked.includes(value)) {
        const data = prevChecked.filter((item) => item !== value);
        return data;
      } else {
        const data = [...prevChecked, value];
        return data;
      }
    });
  };

  useEffect(() => {
    dispatch(setEquipmentTypes(checked));
    handleEquipmentTypes(checked);
  }, [checked]);

  useEffect(() => {
    setAllTypes(appConstants.equipmentTypes);
  }, []);

  const dragStart = (e, i) => {
    draggedItem.current = i;
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", draggedItem.current);
  };

  const dragEnd = (e) => {
    setChecked((prevChecked) => {
      const fromIndex = draggedItem.current;
      const toIndex = overItem.current;
      const updatedChecked = [...prevChecked];
      updatedChecked.splice(toIndex, 0, updatedChecked.splice(fromIndex, 1)[0]);
      return updatedChecked;
    });
    dispatch(setEquipmentTypes(checked));
  };
  const dragOver = (e, i) => {
    e.preventDefault();
    overItem.current = i;
  };

  return (
    <div>
      <FormGroup>
        <Typography
          variant="h6"
          fontWeight={"bold"}
          component="h3"
          color="white"
        >
          Configure Your Equipment Preferences
        </Typography>
        <Typography variant="subtitle1" component="h2" color="white">
          Current Equipment Selection
        </Typography>
        {checked.map((item, i) => (
          <FormControlLabel
            key={i}
            className="text-white"
            data-id={i}
            draggable="true"
            onDragEnd={dragEnd}
            onDragStart={(e) => dragStart(e, i)}
            onDragOver={(e) => dragOver(e, i)}
            control={
              <Checkbox
                className="text-white"
                size="medium"
                value={item}
                onChange={handleChange}
                checked={checked.includes(item)}
              />
            }
            label={
              item
                .replace(/([a-z])([A-Z])/g, '$1 $2')
                .charAt(0)
                .toUpperCase() + item.slice(1).replace(/([A-Z])/g, ' $1')
            }
          />
        ))}

        <Typography variant="subtitle1" component="h2" color="white">
          Available Equipment Types
        </Typography>
        {allTypes.map(
          (item, i) =>
            !checked.includes(item.value) && (
              <FormControlLabel
                key={i}
                className="text-white"
                control={
                  <Checkbox
                    className="text-white"
                    size="medium"
                    value={item.value}
                    onChange={handleChange}
                    checked={checked.includes(item.value)}
                  />
                }
                label={item.title}
              />
            )
        )}
      </FormGroup>
    </div>
  );
}

export default EquipmentTypes;
