import React from "react";
import { Link } from "react-router-dom";
import ButtonPrimary from "../../../../components/ui/Buttons/ButtonPrimary";
import Benefits from "./Benefits";
import Faq from "./Faq";
import "./index.scss";

function Carrier() {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="steps-align">
              <h2 className="font-weight-bold">1. Build your online presence</h2>
              <div className="small-text">
                <p>Build a modern looking website in a few clicks.</p>
                <p>Manage your entire operations in one place.</p>
              </div>
              <Link to="/instant-quote">
                <ButtonPrimary title="build it now" />
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6"></div>
          <div className="col-12 col-md-6">
            <div className="steps-align">
              <h2 className="font-weight-bold">2. Share your capacities everywhere</h2>
              <h5 className="font-weight-bold mt-5">Share your capacity everywhere</h5>
              <div className="small-text">
                <p>
                  Don't just look for shippers and brokers. Truckpedia will
                  bring
                </p>
                <p>
                  shippers, brokers and 3PL's together to book your trucks in
                  real-time.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="steps-align">
              <h2 className="font-weight-bold">3. Promote and scale!</h2>
            </div>
          </div>
        </div>

        <Benefits />
        <Faq />
      </div>
    </div>
  );
}

export default Carrier;
