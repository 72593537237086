import React from "react";
import "../index.scss";
import Input from "../../../../components/ui/Truckpedia/Input/Input";
import ButtonPrimary from "../../../../components/ui/Buttons/ButtonPrimary";
import apple from "../../../../assets/images/truckpedia/auth/apple-logo.svg";
import facebook from "../../../../assets/images/truckpedia/auth/facebook-logo.svg";
import google from "../../../../assets/images/truckpedia/auth/google-logo.svg";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { register } from "../../../../stores/actions/userActions";
import { connect } from "react-redux";

function SignUp({handleRegister}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (payload) => {
    handleRegister(payload);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="container">
        <div className="auth-card">
          <div>
            <h2 className="font-weight-bold mb-3">Create an account</h2>
            <div>
              <label className="fw-light mb-1">I am a</label>
              <select
                className="form-select"
                placeholder="Select a role"
                aria-label="role"
                {...register("role", { required: "role is required!" })}
              >
                <option value="carrier">Carrier</option>
                <option value="shipper">Shipper</option>
                <option value="broker">Broker</option>
              </select>
            </div>
            {errors.role && (
              <span className="text-danger">{errors.role.message}</span>
            )}
            <div>
              <Input
                label="Email address"
                className="mt-3"
                register={{
                  ...register("email", {
                    required: "email is required!",
                  }),
                }}
              />
              {errors.email && (
                <span className="text-danger">{errors.email.message}</span>
              )}
              <Input
                label="First name"
                className="mt-3"
                register={{
                  ...register("firstName", {
                    required: "first name is required!",
                  }),
                }}
              />
              {errors.firstName && (
                <span className="text-danger">{errors.firstName.message}</span>
              )}
              <Input
                label="Last name"
                register={{
                  ...register("lastName", {
                    required: "last name is required!",
                  }),
                }}
                className="mt-3"
              />

              {errors.lastName && (
                <span className="text-danger">{errors.lastName.message}</span>
              )}

              <Input
                label="Password"
                register={{
                  ...register("password", {
                    required: "password is required!",
                  }),
                }}
                type="password"
                className="mt-3"
              />
              {errors.password && (
                <span className="text-danger">{errors.password.message}</span>
              )}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label
                  className="form-check-label small-text-color small fw-light"
                  htmlFor="flexCheckDefault"
                >
                  Keep me signed in
                </label>
              </div>
              <div className="mt-3">
                <span className="small-text-color small fw-light">
                  Selecting this checkbox will keep you signed into your account
                  on this device until you sign out . Do not select this on
                  shared devices.
                </span>
                <p className="small-text-color small mt-3 fw-light">
                  By creating an account, I agree to the Truckpedia{" "}
                  <a
                    href="www"
                    className="fw-light"
                    style={{ color: "#7367F0" }}
                  >
                    Terms and Conditions and Privacy Statement
                  </a>
                </p>
              </div>
              <div>
                <ButtonPrimary title="CONTINUE" className="w-100 mt-2" />
                <div className="mt-2 text-center">
                  <span className="fw-light small">
                    Already have an account?{" "}
                    <Link to="/login">
                      <span className="color-primary">Sign in</span>
                    </Link>
                  </span>
                </div>
              </div>
              <div className="container">
                <div className="d-flex justify-content-evenly mt-4">
                  <span className="fw-light small">Or continue with</span>
                  <div className="auth-icons">
                    <img src={apple} alt="apple-logo" />
                    <img src={facebook} alt="facebook-logo" />
                    <img src={google} alt="google-logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

const mapStateToProps = (state) => ({
  accessLevel: state.accessLevel.level,
});

const mapStateToDispatch = (dispatch) => ({
  handleRegister: (payload) => dispatch(register(payload)),
});

export default connect(mapStateToProps, mapStateToDispatch)(SignUp);
