import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import { checkIfElementsAreNotEmpty } from "../../../helpers";

function TeamCard({ name, designation, className }) {
  const element = useRef(null);
  const [hasData, setHasData] = useState(true);
  useEffect(() => {
    const observer = new MutationObserver(detect);
    observer.observe(element.current, {
      childList: true,
      subtree: true,
      attributes: true,
    });
  }, []);

  const detect = () => {
    const images = $(element.current).find(".--image");
    const texts = $(element.current).find(".--text");
    const allChangedElements = $.merge(images, texts);
    const isExists = checkIfElementsAreNotEmpty(allChangedElements);
    setHasData(isExists);
  };
  return (
    <div className={`card-component ${!hasData ? "d-none" : ""}`} draggable>
      <div
        style={{
          background: "#7367F0",
          width: "13rem",
          margin: 10,
          borderRadius: 20,
        }}>
        <div ref={element}>
          <div className="component">
            <img
              src={require("../../../assets/images/about/team/member.png")}
              className="w-100 --image"
              alt="img"
            />
          </div>
          <div className="p-3">
            <div className="component">
              <h6 className="--text color-white">{name}</h6>
            </div>
            <div className="component">
              <span className="small --text" style={{ color: "#C5BFFF" }}>
                <span>{designation}</span>
              </span>
              {/* {designation.includes("Company") ||
              designation.includes("company") ? (
                <div>
                  <span
                    className={`small --text ${className}`}
                    style={{ color: "#C5BFFF" }}
                  >
                    {designation}
                  </span>
                  <span className="small --text" style={{ color: "#C5BFFF" }}>
                    {" "}
                    CEO
                  </span>
                </div>
              ) : (
              <span
                className="small --text"
                style={{ color: "#C5BFFF" }}
              >
                 <span data-logo="true">{designation}</span> 
              </span>              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamCard;
