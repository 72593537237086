import React from "react";
import Benefits from "./Benefits";
import Faq from "./Faq";

function ShipperAndBroker() {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="steps-align">
              <h2 className="font-weight-bold">1. Search</h2>
              <div className="small-text">
                <p>
                  Start by searching for your trucks. Apply filters like trailer
                  type, weight
                </p>
                <p>and reviews to narrow your options.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6"></div>
          <div className="col-12 col-md-6">
            <div className="steps-align">
              <h2 className="font-weight-bold">2. Book </h2>
              <div className="small-text">
                <p>
                  Once you've found what you're looking for, learn about the
                  carrier,
                </p>
                <p>
                  read past reviews and safety scores, and get details on
                  cancellation options - then book in just a few clicks
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="steps-align">
              <h2 className="font-weight-bold">3. Deliver</h2>
              <div className="small-text">
                <p>
                  You're all set! Connect with your carrier for any questions
                  and trcking
                </p>
                <p>
                  updates. You can also contact Truckpedia anytime for
                  additional support.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Benefits />
        <Faq />
      </div>
    </div>
  );
}

export default ShipperAndBroker;
