import React, { forwardRef } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { GoogleAutoCompleteOptions } from "../../../constants/constants";
import { TextField } from "@mui/material";
import { setPlace } from "../../../helpers";

const GoogleAutoCompleted = forwardRef((props, ref) => {
  const {
    placeholder,
    required,
    onPlaceSelected,
    // defaultAddress,
    setPickupValue,
    onInput,
    value,
  } = props;

  const handlePlaceSelected = (place) => {
    const { city, state } = extractCityAndState(place);
    const formattedPlace = `${city}, ${state}`;
    if (onPlaceSelected) {
      onPlaceSelected(place, formattedPlace, place.formatted_address);
    }
    setPickupValue(formattedPlace);
    // setSelectedValue(formattedPlace);
    // setSelectedValue(place?.formatted_address);
  };
  // const defaultValuesExists =
  //   defaultAddress && defaultAddress?.city && defaultAddress?.state;

  const { ref: placesRef } = usePlacesWidget({
    apiKey: GoogleAutoCompleteOptions.API_KEY,
    onPlaceSelected: handlePlaceSelected,
    options: {
      fields: GoogleAutoCompleteOptions.fields,
      componentRestrictions: {
        country: GoogleAutoCompleteOptions.countries,
      },
      types: ["geocode"],
    },
  });

  const extractCityAndState = (place) => {
    const { city, state } = setPlace(place);
    return { city, state };
  };

  const handleInputChange = (e) => {
    setPickupValue(e.target.value);
    // setSelectedValue("");
  };

  React.useImperativeHandle(ref, () => ({
    focus: () => {
      placesRef.current.focus();
    },
  }));

  return (
    <TextField
      fullWidth
      variant="outlined"
      placeholder={placeholder}
      size={"small"}
      inputRef={placesRef}
      required={required}
      value={value}
      onChange={handleInputChange}
      onInput={onInput}
    />
  );
});

export default GoogleAutoCompleted;
