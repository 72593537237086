import { combineReducers } from 'redux';
import userReducers from './userReducer';
import accessLevelReducer from './accessLevelReducer';
import imagesReducer from './imagesReducer';
import templateReducer from './templateReducer';
import publishReducer from './publishReducer';
import truckReducer from './truckReducer';

const reducers = combineReducers({
  accessLevel: accessLevelReducer,
  user: userReducers,
  images: imagesReducer,
  template: templateReducer,
  publish: publishReducer,
  truck: truckReducer,
});

export default reducers;
