/* eslint-disable no-underscore-dangle */
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';
import reducers from './reducers';

// eslint-disable-next-line no-undef
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const persistConfig = {
	key: 'root',
	storage: AsyncStorage,
	whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(
	persistedReducer,
	composeEnhancer(applyMiddleware(thunk)),
);
// store.subscribe(() => console.log(store.getState()))
export const persistore = persistStore(store);

