import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Routing } from "./routes/routes";
import { connect } from "react-redux";
import { setUserData } from "./stores/actions/userActions";
function App() {
  return (
    <div>
      <script>
        {`!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window,document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
     fbq('init', '418939054018293'); 
    fbq('track', 'PageView');
    `}
      </script>
      <noscript>
        {`
          <img height="1" width="1" 
          src="https://www.facebook.com/tr?id=418939054018293&ev=PageView
          &noscript=1"/>
        `}
      </noscript>
      <Routing />
    </div>
  );
}

const mapStateToProps = (state) => ({
  token: state.user.token,
  template: state.template,
});

const mapDispatchToProps = (dispatch) => ({
  setUserData: (user) => dispatch(setUserData(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
