import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Grid, Typography } from '@mui/material';
import Flatpickr from '../../../components/ui/DatePicker';
import ButtonPrimary from '../../../components/ui/Buttons/ButtonPrimary';
import ButtonOutline from '../../../components/ui/Buttons/ButtonOutline';

const ReservationForm = ({ onSubmit, onComplete, goBack}) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const pickUpDate = watch('pickUpDate', []);
  const deliveryDate = watch('deliveryDate', []);
  const internalSubmit = (data, e) => {
    e.preventDefault();
    onSubmit(data);
    onComplete();
  };
  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const handleKeyDown = (e) => {
    if (e.key === ' ' || e.keyCode === 32) {
      e.preventDefault();
    }
  };

  return (
    <form onSubmit={handleSubmit(internalSubmit)}>
      <Grid
        container
        spacing={1}
        style={{ maxHeight: '400px', overflow: 'auto' }}
      >
        <Grid item xs={5.5} className="f-b">
          First name
        </Grid>
        <Grid item xs={5.5} className="f-b">
          Last name
        </Grid>
        <Grid item xs={5.5} className='pt-0'>
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                fullWidth
                variant="outlined"
                size={'small'}
                {...field}
                error={!!errors.firstName}
                helperText={errors.firstName ? 'First name is required.' : ''}
              />
            )}
            rules={{ required: true }}
          />
        </Grid>
       
        <Grid item xs={5.5} className='pt-0'>
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                fullWidth
                variant="outlined"
                size={'small'}
                {...field}
                error={!!errors.lastName}
                helperText={errors.lastName ? 'Last name is required.' : ''}
              />
            )}
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={5.5} className="f-b">
          Company name
        </Grid>
        <Grid item xs={5.5} className="f-b">
          Phone number
        </Grid>
        <Grid item xs={5.5} className='pt-0'>
          <Controller
            name="companyName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                autoComplete='organization'
                fullWidth
                variant="outlined"
                size={'small'}
                type="text" 
                {...field}
                error={!!errors.companyName}
                helperText={errors.companyName ? 'Company name is required.' : ''}
              />
            )}
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={5.5} className='pt-0'>
          <Controller
            name="phone"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                fullWidth
                variant="outlined"
                size={'small'}
                {...field}
                error={!!errors.phone}
                helperText={errors.phone ? 'Phone number is required.' : ''}
              />
            )}
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={5.5} className="f-b">
          Email
        </Grid>
        <Grid item xs={5.5} className="f-b">
          CC Emails
        </Grid>
        <Grid item xs={5.5} className='pt-0'>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                fullWidth
                variant="outlined"
                size={'small'}
                {...field}
                error={!!errors.email}
                helperText={
                  errors.email ? 'Please enter a valid email address.' : ''
                }
              />
            )}
            rules={{ required: true, pattern: emailPattern }}
          />
        </Grid>
        <Grid item xs={5.5} className='pt-0'>
          <Controller
            name="ccEmails"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <>
                <TextField
                fullWidth
                variant="outlined"
                size={'small'}
                {...field}
                error={!!errors.ccEmails}
                onKeyDown={handleKeyDown}
                helperText={
                  errors.ccEmails ? 'Please enter a valid email address.' : ''
                }
              />
                <p className="small text-xs">Separate multiple emails with commas</p>
              </>
            )}
            rules={{
              validate: (value) => {
                const emails = value
                  .split(',')
                  .map((email) => email.trim())
                  .filter((email) => email !== ''); // Remove empty entries
                const invalidEmails = emails.filter(
                  (email) =>
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
                );
                return invalidEmails.length === 0;
              },
            }}
          />
        </Grid>
        <Grid item xs={5.5} className="f-b">
          Select pickup date
        </Grid>
        <Grid item xs={5.5} className="f-b">
          Select delivery date
        </Grid>
        <Grid item xs={5.5} className="ml-0 pt-0">
          <Controller
            name="pickUpDate"
            control={control}
            defaultValue={pickUpDate}
            rules={{
              required: "Pickup date is required",
            }}
            render={({ field: { value, onChange } }) => {
              return (
                <Flatpickr
                  field={value}
                  value={value}
                  onChange={onChange}
                  mode={'single'}
                  placeholder={''}
                />
              );
            }}
          />
             {errors.pickUpDate && (
              <Typography variant="caption" color="error">
                {errors.pickUpDate.message}
              </Typography>
            )}
        </Grid>
        <Grid item xs={5.5} className='pt-0'>
          <Controller
            name="deliveryDate"
            control={control}
            defaultValue={deliveryDate}
            rules={{
              required: "Delivery date is required",
            }}
            render={({ field: { value, onChange } }) => {
              return (
                <Flatpickr
                  field={value}
                  value={value}
                  onChange={onChange}
                  mode={'single'}
                  placeholder={''}
                />
              );
            }}
          />
             {errors.deliveryDate && (
              <Typography variant="caption" color="error">
                {errors.deliveryDate.message}
              </Typography>
            )}
        </Grid>
        <Grid item xs={11} className="f-b">
          Weight in lbs
        </Grid>
        <Grid item xs={5.5} className='pt-0'>
          <Controller
            name="weightInLbs"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                fullWidth
                variant="outlined"
                size={'small'}
                type="number"
                {...field}
                error={!!errors.weightInLbs}
                helperText={errors.weightInLbs ? 'Weight is required.' : ''}
              />
            )}
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={11} className="f-b">
         <ButtonOutline
            type="button"
            className="mt-4"
            sx={{color: '#7367f0', border: '1px solid #7367f0'}}
            title={'Back'}
            handleClick={goBack}
          />
          <ButtonPrimary
            type="submit"
            variant="contained"
            color="primary"
            className="mt-4 mx-2"
            title={'Submit'}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default ReservationForm;
