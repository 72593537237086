import apiModule from "../services/apiModule";

const { apiClient } = apiModule();

const checkWebsiteUrl = async (param) => {
  apiClient.setContentType("application/json");
  const response = await apiClient.get("truckpedia/checkNewBusinessUrl", {
    ...param,
  });
  if (response.data && response.result) {
    return response.data.payload;
  }

  return response.data;
};

const checkWebsiteCustomDomain = async (param) => {
  apiClient.setContentType("application/json");
  const response = await apiClient.get("truckpedia/checkNewCustomDomain", {
    ...param,
  });
  if (response.data && response.result) {
    return response.data.payload;
  }

  return response.data;
};

export { checkWebsiteUrl, checkWebsiteCustomDomain };
