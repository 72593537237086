import axios from "axios";
import apiModule from "../services/apiModule";
import config from "../config";

const { apiClient } = apiModule();

const searchAvailableTrucks = async (payload) => {
  apiClient.setContentType("application/json");
  const response = await axios.get(
    `${config.NODE_SERVER_URL}/marketplace/lanes/search`,
    {
      params: payload,
    }
  );
  if (response.data && response.data?.result) {
    return response.data;
  }

  return response;
};
const reserveRequestFromCarrierWebsite = async (payload) => {
  apiClient.setContentType("application/json");
  const response = await apiClient.post(
    "truckpedia/reserveRequestFromCarrierWebsite",
    { ...payload }
  );
  if (response.data && response.result) {
    return response.data.payload;
  }
  return response.data;
};

export { searchAvailableTrucks, reserveRequestFromCarrierWebsite };
