/* eslint-disable react-hooks/exhaustive-deps */
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomSlider from "../../../../components/ui/Slider/Slider";
import rgba2hex from "../../../../utils/rgba2hex";
import "./index.scss";
import property from "./propertiesJson.json";
import $ from "jquery";
function PropertiesPanel({ panel, setTemplateDataToHtml }) {
  const state = useSelector((state) => state.template);
  const [color, setColor] = useState("#fff");
  const [alignment, setAlignment] = useState("auto");
  const [appearance, setAppearance] = useState("none");
  const [fontWeight, setFontWeight] = useState("");
  const [fontSize, setFontSize] = useState("");
  // const [letterSpacing, setLetterSpacing] = useState("");
  const [lineSpacing, setLineSpacing] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");

  const textColor = state.textProperties.color;
  const textFontSize = parseInt(state.textProperties["font-size"]);
  const textAlign = state.textProperties["text-align"];
  const textTransform = state.textProperties["text-transform"];
  const textFontWeight = state.textProperties["font-weight"];
  // const textLetterSpacing = Math.abs(
  //   parseInt(state.textProperties["letter-spacing"])
  // );
  const textLineSpacing = parseInt(state.textProperties["line-height"]);
  const widthProp = parseInt(state.textProperties.width);
  const heightProp = parseInt(state.textProperties.height);
  useEffect(() => {
    if (textColor) {
      const convertColorCode = rgba2hex(textColor);
      setColor(convertColorCode);
    }
  }, [textColor]);

  useEffect(() => {
    setAlignment(textAlign);
  }, [textAlign]);

  useEffect(() => {
    setAppearance(textTransform);
  }, [textTransform]);

  useEffect(() => {
    setFontWeight(textFontWeight);
  }, [textFontWeight]);

  useEffect(() => {
    setFontSize(textFontSize);
  }, [textFontSize]);

  useEffect(() => {
    setLineSpacing(textFontSize);
  }, [textLineSpacing]);

  // useEffect(() => {
  //   setLetterSpacing(textLetterSpacing);
  // }, [textLetterSpacing]);

  useEffect(() => {
    setWidth(widthProp);
  }, [widthProp]);

  useEffect(() => {
    setHeight(heightProp);
  }, [heightProp]);

  const handleColorFunc = (event) => {
    setColor(event.target.value);
    $(".active .--text").css("color", event.target.value);
    setTemplateDataToHtml();
  };

  const handleAlignment = (event) => {
    setAlignment(event.target.value);
    $(".active").find(".--text").css("text-align", event.target.value);
    setTemplateDataToHtml();
  };

  const handleAppearance = (event) => {
    setAppearance(event.target.value);
    $(".active").find(".--text").css("text-transform", event.target.value);
    setTemplateDataToHtml();
  };
  const handleFontWeight = (event) => {
    setFontWeight(event.target.value);
    $(".active .--text").css("font-weight", event.target.value);
    setTemplateDataToHtml();
  };

  const handleFontSize = (event) => {
    setFontSize(event);
    $(".active").find(".--text").css("font-size", parseInt(event));
    setTemplateDataToHtml();
  };

  // const handleLetterSpacing = (value) => {
  //   setLetterSpacing(value);
  //   $(".active").find(".--text").css("letter-spacing", parseInt(value));
  // };

  const handleLineSpacing = (value) => {
    setLineSpacing(value);
    $(".active").find(".--text").css("line-height", parseInt(value));
    setTemplateDataToHtml();
  };

  const handleImageWidth = (value) => {
    setWidth(value);
    $(".active").find(".--image").css("width", `${value[0]}px`);
    setTemplateDataToHtml();
  };

  const handleImageHeight = (value) => {
    setHeight(value);
    $(".active").find(".--image").css("height", `${value[0]}px`);
    setTemplateDataToHtml();
  };

  if (panel === "text" || panel === "link") {
    return (
      <div>
        <div className="field">
          <div className="d-flex justify-content-between">
            <label className="mb-2">Color</label>
            <p className="text-white text-uppercase">{color}</p>
          </div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <input
                type="color"
                value={color}
                onChange={handleColorFunc}
                className="w-100"
              />
            </Grid>
          </Grid>
        </div>

        <Grid mt={2}>
          <div className="field">
            <div className="d-flex justify-content-between">
              <label className="mb-2">Font size</label>
              <p className="text-white">{fontSize}px</p>
            </div>
            <CustomSlider handleSliderValue={handleFontSize} value={fontSize} />
          </div>
        </Grid>
        <Grid mt={2}>
          <div className="field">
            <label>Alignment</label>
            <select
              className="form-control mt-2"
              value={alignment}
              onChange={handleAlignment}
            >
              {property.alignments.map((item) => (
                <option value={item.value} key={item.value}>
                  {item.text}
                </option>
              ))}
            </select>
          </div>
        </Grid>

        <Grid mt={2}>
          <div className="field">
            <label>Appearance</label>
            <select
              className="form-control mt-2"
              value={appearance}
              onChange={handleAppearance}
            >
              {property.appearance.map((item) => (
                <option value={item.value} key={item.value}>
                  {item.text}
                </option>
              ))}
            </select>
          </div>
        </Grid>

        <Grid mt={2}>
          <div className="field">
            <label className="mb-2">Font weight</label>
            <select
              className="form-control mt-2"
              value={fontWeight}
              onChange={handleFontWeight}
            >
              {property.fontWeight.map((item) => (
                <option value={item.value} key={item.value}>
                  {item.text}
                </option>
              ))}
            </select>
          </div>
        </Grid>
        {/* 
        <Grid mt={2}>
          <div className="field">
            <div className="d-flex justify-content-between">
              <label className="mb-2">Letter Spacing</label>
              <p className="text-white">{letterSpacing}px</p>
            </div>
            <CustomSlider
              handleSliderValue={handleLetterSpacing}
              value={letterSpacing}
            />
          </div>
        </Grid> */}

        <Grid mt={2}>
          <div className="field">
            <div className="d-flex justify-content-between">
              <label className="mb-2">Line Spacing</label>
              <p className="text-white">{lineSpacing}px</p>
            </div>

            <CustomSlider
              handleSliderValue={handleLineSpacing}
              value={lineSpacing}
            />
          </div>
        </Grid>
      </div>
    );
  }
  if (panel === "image") {
    return (
      <Grid mt={2}>
        <div className="field">
          <div className="d-flex justify-content-between">
            <label>Width</label>
            <p className="text-white">{width}px</p>
          </div>

          <CustomSlider handleSliderValue={handleImageWidth} value={width} />
        </div>
        <div className="field mt-3">
          <div className="d-flex justify-content-between">
            <label>Height</label>
            <p className="text-white">{height}px</p>
          </div>
          <CustomSlider handleSliderValue={handleImageHeight} value={height} />
        </div>
      </Grid>
    );
  }
}

export default PropertiesPanel;
