import React, { useEffect, useRef, useState } from "react";
import "./index.css";
function Range({ title, editable, value }) {
  const element = useRef(null);
  const [newValue, setNewValue] = useState(value);
  useEffect(() => {
    const MutationObserver = window.MutationObserver; // for triggering html change
    const observer = new MutationObserver(detect);
    observer.observe(element.current, {
      childList: true,
    });
  }, [element]);
  const detect = () => {
    const updatedVal = Number(element.current.innerText.replace("%", "")); // converting string into number
    setNewValue(updatedVal);
  };
  return (
    <div className="skills-bar">
      <div className="bar">
        <div className="d-flex justify-content-between">
          <div className={`info ${editable && "component"}`}>
            <span className={editable && "--text"}>{title}</span>
          </div>
        </div>
        <div
          className={`progress-line html ${
            !element.current?.innerText ? "d-none" : ""
          }`}>
          <span style={{ width: newValue + "%" }}></span>
          <div className={editable && "component"}>
            <small
              ref={element}
              className={`percentage ${editable && "--text"}`}
              onInput={detect}
              data-number="true">
              {newValue} %
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Range;
