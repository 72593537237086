/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
import React, { useEffect, useState } from "react";
import ButtonPrimary from "../../../../components/ui/Buttons/ButtonPrimary";
import "./index.scss";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  checkWebsiteCustomDomain,
  checkWebsiteUrl,
} from "../../../../api/checkWebsiteUrl";
import { timezoneOptions } from "../../../../constants/constants";
import { connect } from "react-redux";
import { publishTemplate } from "../../../../stores/actions/publishActions";
import Loader from "../../../../components/ui/Loader/Loader";
import { useParams } from "react-router";
import config from "../../../../config";
const PublishInput = React.forwardRef(
  (
    { register, type, placeholder, handleInput, value, disabled, focus },
    ref
  ) => {
    return (
      <input
        {...register}
        type={type}
        placeholder={placeholder}
        onInput={handleInput}
        defaultValue={value}
        className="publish-field"
        onFocus={focus}
        disabled={disabled}
      />
    );
  }
);

const Publish = ({ handleTemplatePublish, publish, user, token }) => {
  const { businessURL } = useParams(); // fetch businessUrl from route
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [websiteUrlAvailable, setWebsiteUrlAvailable] = useState("");
  const [customDomainAvailable, setCustomDomainUrlAvailable] = useState("");
  const [publishMode, setPublishMode] = useState("truckpedia");
  const [customDomain, setCustomDomain] = useState(
    user.user?.admin_company_details?.customDomain
  );
  const subdomain = window.location.hostname.split(".")[0];
  useEffect(() => {
    if (customDomain) {
      setPublishMode("custom");
    }
  }, [customDomain]);

  const Checkbox = ({ name, title, handleClick }) => {
    return (
      <div className="d-flex align-items-center gap-4">
        <div className="round">
          <input
            type="radio"
            checked={name === publishMode}
            onChange={handleClick}
            name="radio"
            id={title}
          />
          <label htmlFor={title}></label>
        </div>
        <span className="color-white mt-3 h5 ms-2 radio-label">{title}</span>
      </div>
    );
  };

  const checkWebsiteUrlApi = () => {
    if (!websiteUrl.includes(".")) {
      const params = {
        url: websiteUrl,
      };
      checkWebsiteUrl(params).then((data) => {
        if (data.result) {
          setWebsiteUrlAvailable("URL is available");
        } else {
          setWebsiteUrlAvailable(data.message);
        }
      });
    } else {
      setWebsiteUrlAvailable("The given url is wrong.");
    }
  };

  const checkWebsiteCustomDomainApi = () => {
    const params = {
      customDomain: customDomain,
    };
    checkWebsiteCustomDomain(params).then((data) => {
      if (data.result) {
        setCustomDomainUrlAvailable("URL is available");
      } else {
        setCustomDomainUrlAvailable(data.message);
      }
    });
  };

  useEffect(() => {
    setWebsiteUrlAvailable("");
    setWebsiteUrl("");
  }, [publishMode]);

  useEffect(() => {
    let timeoutId;
    timeoutId = setTimeout(() => {
      if (publishMode === "custom" && customDomain) {
        checkWebsiteCustomDomainApi();
      }
      if (publishMode === "truckpedia" && websiteUrl) {
        checkWebsiteUrlApi();
      }
    }, 300);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [customDomain, websiteUrl, !businessURL]);

  const handleWebsiteUrlInput = (input) => {
    // TODO: Code is buggy. If I input "zbu.", it tells me it's wrong. But after I remove the dot, it still tells me it's wrong without rechecking.
    /*if (input.target.value.includes(".")) {
      setWebsiteUrlAvailable("Please check business url.");
      return;
    }*/
    setWebsiteUrl(input.target.value);
  };

  const handleCustomDomain = (input) => {
    // if (input.target.value.substring(0, 1) !== "w") {
    //   setCustomDomainUrlAvailable("Start with host name www.");
    //   return;
    // }
    setCustomDomain(input.target.value);
  };
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const onSubmit = (formData) => {
    if (publishMode === "custom") {
      // if (formData.customDomain.substring(0, 1) !== "w") {
      //   setWebsiteUrlAvailable("Start with host name www.");
      //   return;
      // }
      formData.business_url = "";
    }
    if (publishMode === "truckpedia") {
      formData.customDomain = "";
      if (formData.business_url.match(/\./g)) {
        setWebsiteUrlAvailable("Please check business url.");
        return;
      }
    }

    // if (!formData.business_url) return;
    delete formData.passwordConfirmation;
    handleTemplatePublish(formData, businessURL);
  };

  const generateUrl = () => {
    // www.zbu.io, carriers.truckpedia.io, www.truckpedia.res.do
    if (config.IS_PROD) {
      if (websiteUrl) {
        return config.TRUCKPEDIA_ORIGIN.replace("carriers", websiteUrl);
      } else {
        if (subdomain !== "carriers") {
          return config.TRUCKPEDIA_ORIGIN.replace("carriers", subdomain);
        }
      }
    } else {
      // localhost
      if (websiteUrl) {
        return `${websiteUrl}.${config.TRUCKPEDIA_ORIGIN}`;
      } else {
        if (subdomain !== "carriers") {
          return `${subdomain}.${config.TRUCKPEDIA_ORIGIN}`;
        }
      }
    }
  };

  if (publish && publish.isLoading) {
    return <Loader fullPage={true} />;
  }

  return (
    <div>
      <ToastContainer hideProgressBar />

      <h3 className="color-white font-weight-bold">Publish</h3>

      <div className="my-4">
        <p className="color-white font-weight-bold">Your Account </p>
        <p className="note">
          Enter the required fields you'd like to use for your brand new
          Truckpedia account (which you'll use this to manage this site once
          it's published).
        </p>
        {/* uncomment for login UI  */}
        {/* <span className="color-white font-weight-bold">
          Already have an account?
          <a href="https://dashboard.truckpedia.io/login" target='_blank' className="color-white font-weight-bold text-decoration-underline">
            Log in
          </a>
        </span> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          {!token ? (
            <div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <PublishInput
                    type="text"
                    register={{
                      ...register("first_name", {
                        required: "first name is required!",
                      }),
                    }}
                    placeholder="First name"
                  />
                  {errors.first_name && (
                    <span className="text-danger">
                      {errors.first_name.message}
                    </span>
                  )}
                </div>
                <div className="col-12 col-md-6">
                  <PublishInput
                    register={{
                      ...register("last_name", {
                        required: "last name is required!",
                      }),
                    }}
                    type="text"
                    placeholder="Last name"
                  />
                  {errors.last_name && (
                    <span className="text-danger">
                      {errors.last_name.message}
                    </span>
                  )}
                </div>
              </div>
              <PublishInput
                register={{
                  ...register("email", {
                    required: "email is required!",
                  }),
                }}
                type="text"
                placeholder="Email"
              />
              {errors.email && (
                <span className="text-danger">{errors.email.message}</span>
              )}
              <PublishInput
                register={{
                  ...register("password", {
                    required: "password is required!",
                  }),
                }}
                type="password"
                placeholder="Password"
              />
              {errors.password && (
                <span className="text-danger">{errors.password.message}</span>
              )}
              <PublishInput
                register={{
                  ...register("passwordConfirmation", {
                    required: "Please confirm password!",
                    validate: {
                      matchesPreviousPassword: (value) => {
                        const { password } = getValues();
                        return password === value || "Passwords should match!";
                      },
                    },
                  }),
                }}
                type="password"
                placeholder="Confirm password"
              />
              {errors.passwordConfirmation && (
                <span className="text-danger">
                  {errors.passwordConfirmation.message}
                </span>
              )}
              <PublishInput
                register={{
                  ...register("business_name", {
                    required: "name is required!",
                  }),
                }}
                type="text"
                placeholder="Business name"
              />
              {errors.business_name && (
                <span className="text-danger">
                  {errors.business_name.message}
                </span>
              )}
              <PublishInput
                register={{
                  ...register("usdot_number", {
                    required: "usdot is required!",
                    valueAsNumber: true,
                  }),
                }}
                type="number"
                placeholder="US DOT number"
              />
              {errors.usdot_number && (
                <span className="text-danger">
                  {errors.usdot_number.message}
                </span>
              )}
              <PublishInput
                register={{
                  ...register("business_email", {
                    required: "business email is required!",
                  }),
                }}
                type="text"
                placeholder="Billing email"
              />
              {errors.business_email && (
                <span className="text-danger">
                  {errors.business_email.message}
                </span>
              )}
              <PublishInput
                register={{
                  ...register("load_unique_id_started_from", {
                    required: "Load id is required!",
                    valueAsNumber: true,
                  }),
                }}
                type="number"
                placeholder="Load # start from"
              />
              {errors.load_unique_id_started_from && (
                <span className="text-danger">
                  {errors.load_unique_id_started_from.message}
                </span>
              )}
              <div className="row">
                <div className="col-12 col-md-6">
                  <PublishInput
                    register={{
                      ...register("address", {
                        required: "address is required!",
                      }),
                    }}
                    type="text"
                    placeholder="Address (street address)"
                  />
                  {errors.address && (
                    <span className="text-danger">
                      {errors.address.message}
                    </span>
                  )}
                </div>
                <div className="col-12 col-md-6">
                  <PublishInput
                    register={{
                      ...register("city", {
                        required: "city is required!",
                      }),
                    }}
                    type="text"
                    placeholder="City"
                  />
                  {errors.city && (
                    <span className="text-danger">{errors.city.message}</span>
                  )}
                </div>
                <div className="col-12 col-md-6">
                  <PublishInput
                    register={{
                      ...register("state", {
                        required: "state is required!",
                      }),
                    }}
                    type="text"
                    placeholder="State"
                  />

                  {errors.state && (
                    <span className="text-danger">{errors.state.message}</span>
                  )}
                </div>
                <div className="col-12 col-md-6">
                  <PublishInput
                    register={{
                      ...register("zipcode", {
                        required: "zipcode is required!",
                      }),
                    }}
                    type="text"
                    placeholder="Zip code"
                  />
                  {errors.zipcode && (
                    <span className="text-danger">
                      {errors.zipcode.message}
                    </span>
                  )}
                </div>
                <div className="col-12 col-md-6">
                  <PublishInput
                    register={{
                      ...register("phone_number", {
                        required: "number is required!",
                      }),
                    }}
                    type="number"
                    placeholder="Phone number"
                  />
                  {errors.phone_number && (
                    <span className="text-danger">
                      {errors.phone_number.message}
                    </span>
                  )}
                </div>
                <div className="col-12 col-md-6">
                  <PublishInput
                    register={{ ...register("fax_number") }}
                    type="text"
                    placeholder="Fax"
                  />
                </div>
              </div>

              <select
                className="publish-field"
                {...register("timezone", {
                  required: "timezone is required!",
                })}>
                {Object.entries(timezoneOptions()).map((item, index) => (
                  <option key={index} value={item[0]}>
                    {item[1]}
                  </option>
                ))}
              </select>

              {errors.timezone && (
                <span className="text-danger">{errors.timezone.message}</span>
              )}
            </div>
          ) : null}

          <Checkbox
            title="Publish to custom domain"
            name="custom"
            handleClick={() => setPublishMode("custom")}
          />

          <div className="mt-2 text-break">
            {publishMode === "custom" && (
              <div className="mb-4">
                <PublishInput
                  register={{
                    ...register("customDomain"),
                  }}
                  type="text"
                  placeholder="Custom domain"
                  value={customDomain}
                  focus={() => setPublishMode("custom")}
                  handleInput={handleCustomDomain}
                />
                {customDomainAvailable && (
                  <span
                    className={
                      customDomainAvailable === "URL is available"
                        ? "text-success"
                        : "text-danger"
                    }>
                    {customDomainAvailable}
                  </span>
                )}
                <br />
                <span className="text-white">
                  Publishes this site to a registered{" "}
                  <strong>custom domain</strong>. Enter the domain above, then
                  add the host records below via its domain provider.(Ex.
                  www.abctrucking.com)
                </span>
              </div>
            )}

            <Checkbox
              title="Publish to truckpedia URL"
              name="truckpedia"
              handleClick={() => setPublishMode("truckpedia")}
            />
            {publishMode === "truckpedia" && (
              <div>
                <span className="color-primary font-weight-bold text-decoration-underline pointer">
                  {generateUrl()}
                </span>
                <PublishInput
                  register={{
                    ...register("business_url"),
                  }}
                  type="text"
                  placeholder="URL"
                  value={
                    subdomain && subdomain !== "carriers"
                      ? subdomain
                      : websiteUrl
                  }
                  handleInput={handleWebsiteUrlInput}
                  focus={() => setPublishMode("truckpedia")}
                />
                {websiteUrlAvailable && (
                  <span
                    className={
                      websiteUrlAvailable === "URL is available"
                        ? "text-success"
                        : "text-danger"
                    }>
                    {websiteUrlAvailable}
                  </span>
                )}
              </div>
            )}
          </div>

          {errors.business_url && (
            <span className="text-danger">{errors.business_url.message}</span>
          )}
          <ButtonPrimary type="submit" title="Submit" className="w-100 mt-3" />
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  accessLevel: state.accessLevel.level,
  publish: state.publish,
  user: state.user,
  images: state.images,
});

const mapStateToDispatch = (dispatch) => ({
  handleTemplatePublish: (formData, businessURL) =>
    dispatch(publishTemplate(formData, businessURL)),
});

export default connect(mapStateToProps, mapStateToDispatch)(Publish);

PublishInput.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
};
