import React from "react";
import "./index.css";
const ButtonPrimary = ({ title, sx, className, type, loading, handleClick, disabled }) => {
  return (
    <button
      type={type}
      className={`button button-primary ${className}`}
      style={sx}
      disabled={disabled ? disabled : loading}
      onClick={handleClick}
    >
      {loading ? (
        <>
          <span
            className="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span>Loading...</span>
        </>
      ) : (
        title
      )}
    </button>
  );
};

export default ButtonPrimary;
