import React from "react";
import ButtonPrimary from "../../../components/ui/Buttons/ButtonPrimary";
import CompanyCard from "../../../components/ui/Contact/CompanyCard";
import Input from "../../../components/ui/Truckpedia/Input/Input";
import "./index.scss";
import email from "../../../assets/images/footer/email.svg";
import phone from "../../../assets/images/footer/phone.svg";
import marker from "../../../assets/images/footer/marker.svg";
function ContactUs() {
  return (
    <div id="template" data-component-id="contact-us">
      <main className="--inner">
        <div className="contact-us">
          <section className="--section fixed">
            <div className="component">
              <div className="hero-wrapper --bg-image">
                <div className="contact-hero-img">
                  <img
                    src={require("../../../assets/images/contact-us/hero_banner.png")}
                    alt="bg img"
                    className="contact-bg-image --image"
                  />
                  <div className="position-absolute">
                    <p>Contact Us</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="container">
            {/* <section className="--section hidden">
              <div className="d-flex company-card-wrapper">
                <CompanyCard />
                <CompanyCard className="scale" />
                <CompanyCard />
              </div>
            </section> */}
            <section className="--section">
              <div className="inquiry">
                <div className="row">
                  <div className="col-md-6">
                    <div className="card shadow border-0">
                      <div className="card-body">
                        <div>
                          <h2 className="h2 fw-semibold">
                            Need to Make an Inquiry?
                          </h2>
                          <div className="row mt-4 g-3">
                            <div className="col-md-6">
                              <Input placeholder="Name*" />
                            </div>
                            <div className="col-md-6">
                              <Input placeholder="Affiliation *" />
                            </div>
                            <div className="col-md-6">
                              <Input placeholder="Email *" />
                            </div>
                            <div className="col-md-6">
                              <Input placeholder="Phone Number *" />
                            </div>
                            <div className="col-md-6">
                              <Input placeholder="Website *" />
                            </div>
                            <div className="col-md-6">
                              <Input placeholder="Inquiry Department *" />
                            </div>
                            <div className="col-md-12">
                              <Input placeholder="Topic *" />
                            </div>
                            <div className="col-md-12">
                              <Input placeholder="Your Message" />
                            </div>
                            <div className="text-center">
                              <ButtonPrimary
                                title="SUBMIT"
                                sx={{ padding: "10px 30px" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="p-5">
                      <div className="component">
                        <h2 className="h1 fw-semibold --text">
                          We take care about transportation for your business
                        </h2>
                      </div>
                      <div className="component">
                        <p className="--text color-small">
                          We are experienced in handling the formalities and
                          documentation required for your imports and exports.
                          We work with all international station to guarantee
                          that your load will safely reach without any delays.
                        </p>
                      </div>
                      <div>
                        <ul className="list-group contact-address">
                          <li className="component icon">
                            <img alt="marker" src={marker} />
                            <span className="component ms-3">
                              <span className="--text">
                                12 Ave, Washington 165, NY CA 54003
                              </span>
                            </span>
                          </li>
                          <li className="icon">
                            <img alt="marker" src={email} />
                            <span className="component ms-3">
                              <span className="--text">info@company.com</span>
                            </span>
                          </li>
                          <li className="component icon">
                            <img alt="marker" src={phone} />
                            <span className="component ms-3">
                              <span className="--text">+31 65 792 63 11</span>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
    </div>
  );
}

export default ContactUs;
