import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Compressor from "compressorjs";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { connect } from "react-redux";
import { setReplaceImages } from "../../../../stores/actions/imagesActions";
import $ from "jquery";
import Loader from "../../../../components/ui/Loader/Loader";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ImageEditor = ({
  handleReplaceImage,
  exitingImage,
  handleUploadedImages,
  images
}) => {
  const [file, setFile] = useState(null);
  const [open, setOpen] = React.useState(false);
  // const state = useSelector(state => state);
  const handleCompressedUpload = (e) => {
    const maxFileSizeInBytes = 10000000;
    let image = e.target.files[0];
    if (image && image.size > maxFileSizeInBytes) {
      setOpen(true);
      document.getElementById("upload").value = "";
    } else {
      if (image) {
        new Compressor(image, {
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          convertSize: 2000000,
          success: (compressedResult) => {
            // compressedResult has the compressed file.
            // Use the compressed file to upload the images to your server.
            setFile(URL.createObjectURL(compressedResult));
            handleReplaceImage(URL.createObjectURL(compressedResult));
            const uniqueImageId = $(".component.active").attr('section-id')
            handleUploadedImages({ id: uniqueImageId, file: compressedResult });
          },
        });
      }
    }
  };

  useEffect(() => {
    setFile(exitingImage);
  }, [exitingImage]);

  const handleClose = (event, reason) => {
    setOpen(false);
    if (reason === "clickaway") {
      return;
    }
  };

  const handlePointerEvents = (isEnabled) => {
    var element = document.getElementById("root");
    element.style.pointerEvents = isEnabled ? "none" : "all";
  };

  if (images.isLoading) {
    handlePointerEvents(true);
    return (
      <Loader
        processingText="Image uploading..."
        textColor={{ color: "#fff" }}
      />
    );
  } else {
    handlePointerEvents(false);
  }

  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="warning"
          sx={{ width: "100%", fontSize: 15 }}
        >
          Image too large <br />
          Sorry, images cannot exceed 10MB in file size.
        </Alert>
      </Snackbar>
      <Button
        variant="contained"
        component="label"
        onChange={handleCompressedUpload}
      >
        Upload
        <input id="upload" hidden accept=".jpg,.jpeg,.png" type="file" />
      </Button>
      <img src={file} alt="preview" className="w-100 mt-3" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  images: state.images,
});

const mapDispatchToProps = (dispatch) => ({
  handleUploadedImages: (image) => dispatch(setReplaceImages(image)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageEditor);
