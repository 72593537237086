import React, { useEffect, useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { TextField, Button, Stack, CircularProgress } from "@mui/material";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getImagesUrls,
  uploadImageSuccess,
} from "../../../../stores/actions/imagesActions";
import {
  createBlogPost,
  updateBlogPost,
  fetchBlogPostDetail,
} from "../../../../stores/actions/templateActions";
import { uploadFileToS3 } from "../../../../api/templateApi";
import "../index.scss";
import PostDetails from "./PostDetails";
const MyEditor = (props) => {
  const { onBack } = props;
  const dispatch = useDispatch();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [uploadedImages, setUploadedImages] = useState([]);
  const [preview, setPreview] = useState(false);
  const [subject, setSubject] = useState("");
  const [subjectError, setSubjectError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { loading, blogDetails } = useSelector((state) => state.template);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const togglePreview = () => {
    setPreview(!preview);
  };

  const getHtml = () => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };

  useEffect(() => {
    if (!loading && !blogDetails && props.blogId) {
      setPreview(false);
      dispatch(fetchBlogPostDetail(props.blogId));
    }
  }, [props.blogId, loading, blogDetails, dispatch]);

  useEffect(() => {
    if (blogDetails && blogDetails.blog && props.blogId) {
      setSubject(blogDetails?.blog?.title);
      let content = "";
      if (typeof blogDetails?.blog?.content !== "object") {
        content = JSON.parse(blogDetails?.blog?.content);
      } else {
        content = blogDetails?.blog?.content;
      }

      const contentBlock = htmlToDraft(content?.description);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );

      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [blogDetails, props.blogId]);

  const handleSubjectChange = (event) => {
    const value = event.target.value;
    setSubject(value);
    if (subjectError && value.trim()) {
      setSubjectError(false);
    }
  };

  const validateSubject = () => {
    if (!subject.trim()) {
      setSubjectError(true);
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (validateSubject()) {
      setIsSaving(true);
      try {
        await handleSave();
        props.navigateToBlogs();
      } catch (error) {}
      setIsSaving(false);
    }
  };
  const handleSave = async () => {
    let blogContent = getHtml();
    const uploadedImagesUrls = [];

    for (let i = 0; i < uploadedImages.length; i++) {
      const payload = uploadedImages[i];
      const imagesS3Url = await dispatch(getImagesUrls(payload));

      if (imagesS3Url && imagesS3Url.length > 0) {
        await uploadFileToS3(imagesS3Url[0].url, payload.file);
        const imageUrl = imagesS3Url[0].url.split("?")[0];
        blogContent = blogContent.replace(payload.localSrc, imageUrl);
        uploadedImagesUrls.push({
          id: payload.id,
          url: imageUrl,
        });
        dispatch(
          uploadImageSuccess({
            id: payload.id,
            url: imageUrl,
          })
        );
      } else {
        console.error("No S3 URL returned");
      }
    }

    const blogData = {
      title: subject,
      content: JSON.stringify({
        description: blogContent,
      }),
    };

    if (props.blogId) {
      blogData.id = props.blogId;
      dispatch(updateBlogPost(blogData));
    } else {
      dispatch(createBlogPost(blogData));
    }
  };

  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const imageObject = {
        file: file,
        localSrc: URL.createObjectURL(file),
      };

      setUploadedImages((prevUploadedImages) => [
        ...prevUploadedImages,
        imageObject,
      ]);

      resolve({ data: { link: imageObject.localSrc } });
    });
  };

  return (
    <>
      <div className="container">
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ marginY: 2 }}
        >
          <Stack spacing={2} direction="row">
            <Button variant="outlined" onClick={onBack}>
              Back
            </Button>
            <Button
              variant="contained"
              sx={{ width: "100px" }}
              onClick={togglePreview}
            >
              {preview ? "Edit" : "Preview"}
            </Button>
          </Stack>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={isSaving}
          >
            Save
            {isSaving && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Button>
        </Stack>
      </div>

      {!preview ? (
        <div className="container">
          <TextField
            label="Subject"
            variant="outlined"
            fullWidth
            margin="normal"
            value={subject}
            onChange={handleSubjectChange}
            error={subjectError}
            helperText={subjectError ? "Subject is required." : ""}
          />
          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            toolbar={{
              image: { uploadCallback: uploadImageCallBack },
            }}
          />
        </div>
      ) : (
        <>
          {/* <Box className='preview-subject' >{ subject }</Box>
          <Paper elevation={ 2 } className='preview'>
            <Box dangerouslySetInnerHTML={ { __html: getHtml() } } />
          </Paper> */}
          <PostDetails title={subject} content={getHtml()} />
        </>
      )}
    </>
  );
};

export default MyEditor;
