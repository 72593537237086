/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
import $ from "jquery";

// add drag and drop script to template
const dragAndDrop = () => {
  // let target = document.getElementById("template");
  // target.classList.add("custom-dropzone");
  // let items = target.getElementsByClassName("component");

  // // eslint-disable-next-line
  // let current = null;
  // var index = 1;
  // // MAKE ITEMS DRAGGABLE
  // for (let i of items) {
  //   let indexPlus = ++index;
  //   // ATTACH DRAGGABLE
  //   i.draggable = true;
  //   $(i)
  //     .attr("data-id", `element-${indexPlus}`)
  //     .css({ cursor: "pointer" })
  //     .attr("order", indexPlus);
  //   // DRAG START
  //   // eslint-disable-next-line
  //   i.ondragstart = (ev) => {
  //     // eslint-disable-next-line
  //     current = i;
  //     for (let it of items) {
  //       if (it === current) {
  //         it.classList.add("hint");
  //       }
  //     }
  //   };

  //   // DRAG ENTER
  //   i.ondragenter = () => {
  //     // eslint-disable-next-line
  //     if (i !== current) {
  //       i.classList.add("drag-over");
  //     }
  //   };

  //   // DRAG LEAVE
  //   i.ondragleave = () => {
  //     i.classList.remove("drag-over");
  //   };

  //   // DRAG END
  //   i.ondragend = () => {
  //     for (let it of items) {
  //       it.classList.remove("hint");
  //       it.classList.remove("drag-over");
  //     }
  //   };

  //   // DRAG OVER - PREVENT THE DEFAULT "DROP", SO WE CAN DO OUR OWN
  //   i.ondragover = (evt) => {
  //     evt.preventDefault();
  //   };

  //   // ON DROP
  //   i.ondrop = (evt) => {
  //     evt.preventDefault();
  //     // eslint-disable-next-line
  //     if (i !== current) {
  //       let currentpos = 0,
  //         droppedpos = 0;
  //       for (let it = 0; it < items.length; it++) {
  //         if (current === items[it]) {
  //           currentpos = it;
  //         }
  //         if (i === items[it]) {
  //           droppedpos = it;
  //         }
  //       }
  //       if (currentpos < droppedpos) {
  //         i.parentNode.insertBefore(current, i.nextSibling);
  //       } else {
  //         i.parentNode.insertBefore(current, i);
  //       }
  //     }
  //   };
  // }
};

export default dragAndDrop;
