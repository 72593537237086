import React, { useState } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { TextField } from '@mui/material';
import './index.css';

const Flatpickr = ({ value, onChange, required, mode, placeholder }) => {
  const [picker, setPicker] = useState(null);

  const initFlatpickr = (ref) => {
    if (!picker && ref) {
      const newPicker = flatpickr(ref, {
        mode: mode,
        dateFormat: 'Y-m-d',
        defaultDate: value,
        onChange: (selectedDates) => {
          onChange(selectedDates);
        },
      });
      setPicker(newPicker);
    }
  };

  return (
    <TextField
      inputRef={initFlatpickr}
      placeholder={placeholder}
      fullWidth
      size={'small'}
      required={required}
    />
  );
};
export default Flatpickr;
