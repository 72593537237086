import React from "react";
const faq = [
    {
      id: "one",
      question: "Will my information be safe on Truckpedia?",
      answer:
        " Yes, Please go to Apple or Google Store to download Truckpedia Driver to access their account. Admin can create a driver login in Setting.",
    },
    {
      id: "two",
      question: "How long does FREE TRIAL last?",
    },
    {
      id: "three",
      question: "How do I reach out to your support for assistance?",
    },
    {
      id: 'four',
      question: 'How do I begin my subscription after my FREE TRIAL ends?'
    },
    {
      id:"five",
      question: 'How do I import my previous customer information to Truckpedia?'
    }
  ];
function Faq() {
  return (
    <div>
      <div>
        <div className="container mt-5 py-5">
          <div className="row">
            <div className="col-md-6">
              <h2 className="h1">Still have questions?</h2>
            </div>
            <div className="col-md-6">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                {faq.map((item) => {
                  return (
                    <div className="accordion-item" key={item.id}>
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#flush-collapse${item.id}`}
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          <span className="font-weight-bold">{item.question}</span>
                        </button>
                      </h2>
                      <div
                        id={`flush-collapse${item.id}`}
                        className={`accordion-collapse collapse ${
                          item.id === "one" ? "show" : ""
                        }`}
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body small-text-color">
                          {item.answer}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
