/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { appConstants } from "../../../../constants/constants";
import { Typography } from "@mui/material";
import { setWebpages } from "../../../../stores/actions/templateActions";
import { useParams } from "react-router";

function NavLinks({ selectedPages, handleNavLinks, setLocalWebpages }) {
  const { businessURL } = useParams();
  const [checked, setChecked] = useState([]);
  const [allWebPages, setAllWebPages] = useState([]);
  const draggedItem = React.useRef(null);
  const overItem = React.useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setChecked(selectedPages);
  }, []);

  const handleChange = (event) => {
    const { value } = event.target;
    setChecked((prevChecked) => {
      if (prevChecked.includes(value)) {
        const data = prevChecked.filter((item) => item !== value);
        return data;
      } else {
        const data = [...prevChecked, value];
        return data;
      }
    });
  };

  useEffect(() => {
    dispatch(setWebpages(checked));
    if (checked.length !== 0) {
      handleNavLinks(checked);
    } else {
      setLocalWebpages([]);
    }
  }, [checked]);

  useEffect(() => {
    setAllWebPages(
      businessURL
        ? appConstants.webpages?.filter((d) => d.route !== "/blogs")
        : appConstants.webpages
    );
  }, []);

  const dragStart = (e, i) => {
    draggedItem.current = i;
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", draggedItem.current);
  };

  const dragEnd = (e) => {
    setChecked((prevChecked) => {
      const fromIndex = draggedItem.current;
      const toIndex = overItem.current;
      const updatedChecked = [...prevChecked];
      updatedChecked.splice(toIndex, 0, updatedChecked.splice(fromIndex, 1)[0]);
      return updatedChecked;
    });
    dispatch(setWebpages(checked));
  };
  const dragOver = (e, i) => {
    e.preventDefault();
    overItem.current = i;
  };

  return (
    <div>
      <FormGroup>
        <Typography
          variant="h6"
          fontWeight={"bold"}
          component="h3"
          color="white"
        >
          Configure Your Navigation Bar
        </Typography>
        <Typography variant="subtitle1" component="h2" color="white">
          Current Web Pages
        </Typography>
        {checked.map((item, i) => (
          <FormControlLabel
            key={i}
            className="text-white"
            data-id={i}
            draggable="true"
            onDragEnd={dragEnd}
            onDragStart={(e) => dragStart(e, i)}
            onDragOver={(e) => dragOver(e, i)}
            control={
              <Checkbox
                className="text-white"
                size="medium"
                value={item}
                onChange={handleChange}
                checked={checked.includes(item)}
              />
            }
            label={appConstants.webpages.find((d) => d.route === item).title}
          />
        ))}

        <Typography variant="subtitle1" component="h2" color="white">
          Available Web Pages
        </Typography>
        {allWebPages.map(
          (item, i) =>
            !checked.includes(item.route) && (
              <FormControlLabel
                key={i}
                className="text-white"
                control={
                  <Checkbox
                    className="text-white"
                    size="medium"
                    value={item.route}
                    onChange={handleChange}
                    checked={false}
                  />
                }
                label={item.title}
              />
            )
        )}
      </FormGroup>
    </div>
  );
}

export default NavLinks;
