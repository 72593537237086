import React from "react";
import ButtonPrimary from "../../../components/ui/Buttons/ButtonPrimary";
import Input from "../../../components/ui/Truckpedia/Input/Input";
import "./index.scss";

function Tracking() {
  return (
    <main className="--inner" id="template" data-component-id="tracking">
      <div className="tracking-hero-wrapper">
        <div className="component">
          <div className="tracking-img --bg-image">
            <img
              src={require("../../../assets/images/tracking/hero_banner.png")}
              alt="bg img"
              className="trucking-bg-image --image"
            />
            <div>
              <p>Tracking</p>
            </div>
          </div>
        </div>
        <div className="tracking-card">
          <div className="card shadow" style={{ width: "44rem" }}>
            <div className="card-body">
              <div className="d-flex align-items-center gap-5">
                <Input label="Truck Reference no." className="w-100" />
                <ButtonPrimary
                  title="Submit"
                  sx={{ padding: "10px 30px" }}
                  className="mt-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Tracking;
