import React, { useEffect } from "react";
import { Range } from "react-range";
const STEP = 1;
const MIN = 0;
const MAX = 2000;

function CustomSlider({ value, handleSliderValue }) {
  const [values, setValues] = React.useState([0]);

  useEffect(() => {
    setValues(value);
    return () => {
      setValues([]);
    };
  }, [value]);

  const handleSliderValues = (values) => {
    setValues(values);
    handleSliderValue(values)
  };

  if(!values) return

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      <Range
        values={[values]}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={handleSliderValues}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "5px",
                width: "100%",
                borderRadius: "4px",
                background: "#548BF4",
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "35px",
              width: "25px",
              borderRadius: "4px",
              backgroundColor: "#FFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 2px 6px #AAA",
            }}
          >
            {/* <div
              style={{
                position: "absolute",
                top: "-38px",
                color: "#fff",
                fontWeight: "bold",
                fontSize: "12px",
                padding: "2px",
                borderRadius: "4px",
                backgroundColor: "#548BF4",
              }}
            >
              {values[0] ? values[0].toFixed(0) : value} px
            </div> */}
            <div
              style={{
                height: "16px",
                width: "5px",
                backgroundColor: isDragged ? "#548BF4" : "#CCC",
              }}
            />
          </div>
        )}
      />
    </div>
  );
}

export default CustomSlider;
