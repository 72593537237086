/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */
import React, { useState, useEffect } from 'react';
import GoogleAutoCompleted from '../../../components/ui/GoogleAutoComplete';
import Flatpickr from '../../../components/ui/DatePicker';
import { Grid, TextField, Typography, FormLabel } from '@mui/material';
import ButtonPrimary from '../../../components/ui/Buttons/ButtonPrimary';
import { useForm, Controller } from 'react-hook-form';
import GoogleMaps from '../../../components/ui/GoogleMapsApi';
import { useDispatch, useSelector } from 'react-redux';
import {
  availableTrucks,
  setQuotesPayload,
} from '../../../stores/actions/truckActions';
import vanIcon from '../../../assets/images/truckpedia/truckType/van.svg';
import BoxTruck from '../../../assets/images/truckpedia/truckType/boxTruck.svg';
import Conestoga from '../../../assets/images/truckpedia/truckType/conestoga.svg';
import flatbed from '../../../assets/images/truckpedia/truckType/flatbed.svg';
import PowerOnly from '../../../assets/images/truckpedia/truckType/powerOnly.svg';
import Reefer from '../../../assets/images/truckpedia/truckType/reefer.svg';
import Sprinter from '../../../assets/images/truckpedia/truckType/sprinter.svg';
import StepDeck from '../../../assets/images/truckpedia/truckType/stepDeck.svg';
import Drayage from '../../../assets/images/truckpedia/truckType/drayage.svg';
import Intermodal from '../../../assets/images/truckpedia/truckType/intermodal.svg';
import './index.scss';
import RateLoader from '../../../components/ui/Loader/RateLoader';
import { useDateFormat } from '../../../hooks/useDateFormat';
import { toast } from 'react-toastify';
import { parseFloatWithTrim } from '../../../helpers';

const InstantQuoteForm = ({ setBookBtn, setReservationData, onComplete }) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const { formatDate } = useDateFormat();
  const quotesPayload = useSelector((state) => state?.truck?.quotesPayload);
  const [pickupLocation, setPickupLocation] = useState({});
  const [deliveryLocation, setDeliveryLocation] = useState({});
  const [pickupLatLng, setPickupLatLng] = useState({});
  const [deliveryLatLng, setDeliveryLatLng] = useState({});
  const [pickupAddress, setPickupAddress] = useState('');
  const [pickupDisplayAddress, setPickupDisplayAddress] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [deliveryDisplayAddress, setDeliveryDisplayAddress] = useState('');
  const [distance, setDistance] = useState(null);
  const selectedDates = watch('pickUpDate', []);
  const [activeType, setActiveType] = useState();
  const [loading, setLoading] = useState(false);
  const [btnTitle, setBtnTitle] = useState('Search');
  const [pickupValue, setPickupValue] = useState();
  const [availableTrucksState, setAvailableTrucksState] = useState(null);
  const [distanceLoading, setDistanceLoading] = useState(false);
  const [truckTypes, setTruckTypes] = useState([]);
  const pickUpDateValue = watch('pickUpDate');
  const truckTypeIconsName = [
    { title: 'Van', icon: vanIcon, value: 'van' },
    { title: 'Reefer', icon: Reefer, value: 'reefer' },
    { title: 'Flatbed', icon: flatbed, value: 'flatbed' },
    { title: 'Step Deck', icon: StepDeck, value: 'stepDeck' },
    { title: 'Power Only', icon: PowerOnly, value: 'powerOnly' },
    { title: 'Conestoga', icon: Conestoga, value: 'conestoga' },
    { title: 'Box Truck', icon: BoxTruck, value: 'boxTruck' },
    { title: 'Sprinter', icon: Sprinter, value: 'sprinter' },
    { title: 'Drayage', icon: Drayage, value: 'drayage' },
    { title: 'Intermodal', icon: Intermodal, value: 'intermodal' },
  ];

  const companyData = useSelector((state) => state?.template?.companyData);
  const equipmentTypes = useSelector(
    (state) => state?.template?.equipmentTypes
  );

  const availableTrucksStatus = useSelector(
    (state) => state?.truck?.availableTrucksStatus
  );
  let pickupField, deliveryField;

  const handleFormSubmit = async (data, e) => {
    e.preventDefault();
    setLoading(true);
    const destinationAddress = {
      city: deliveryLocation?.city || quotesPayload?.deliveryLocation?.city,
      state: deliveryLocation?.state || quotesPayload?.deliveryLocation?.state,
      zipCode:
        deliveryLocation?.zipCode || quotesPayload?.deliveryLocation?.zipCode,
      latitude: deliveryLatLng?.lat || quotesPayload?.deliveryLocation?.lat,
      longitude: deliveryLatLng?.lng || quotesPayload?.deliveryLocation?.lng,
    };
    const originAddress = {
      city: pickupLocation?.city || quotesPayload?.pickupLocation?.city,
      state: pickupLocation?.state || quotesPayload?.pickupLocation?.state,
      zipCode:
        pickupLocation?.zipCode || quotesPayload?.pickupLocation?.zipCode,
      latitude: pickupLatLng?.lat || quotesPayload?.pickupLocation?.lat,
      longitude: pickupLatLng?.lng || quotesPayload?.pickupLatLng?.lng,
    };
    try {
      const payloadData = {
        startDate: formatDate(data.pickUpDate[0]),
        endDate: formatDate(data.pickUpDate[1]),
        destination: JSON.stringify(destinationAddress),
        origin: JSON.stringify(originAddress),
        carrierCompanyId: companyData?.carrierCompanyId,
        distance: distance ? Number(distance) : quotesPayload?.totalMiles,
        weight: data.weight || undefined,
        equipmentTypes: activeType ? [activeType] : undefined,
        page: 1,
        pageSize: 50,
      };
      if (companyData?.carrierCompanyId) {
        const { payload } = await dispatch(availableTrucks(payloadData));
        if (payload?.lanes?.length > 0) {
          setAvailableTrucksState(payload);
        } else {
          infoNotificationToast('No trucks found');
        }
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!quotesPayload) return;
    setReservationData(quotesPayload);
    setActiveType(quotesPayload.equipmentType);
    setPickupLocation(quotesPayload.pickupLocation);
    setDeliveryLocation(quotesPayload.deliveryLocation);
    setPickupLatLng(quotesPayload.pickupLatLng);
    setDeliveryLatLng(quotesPayload.deliveryLatLng);
    setDistance(quotesPayload.totalMiles);
    setPickupAddress(quotesPayload.pickupFullAddress);
    setPickupDisplayAddress(quotesPayload.pickupDisplayAddress);
    setDeliveryAddress(quotesPayload.deliveryFullAddress);
    setDeliveryDisplayAddress(quotesPayload.deliveryDisplayAddress);
    pickupField.onChange(quotesPayload.pickupDisplayAddress);
    deliveryField.onChange(quotesPayload.deliveryLocation);
  }, []);

  useEffect(() => {
    if (!pickupLocation || pickUpDateValue?.length === 0) {
      setAvailableTrucksState(null);
      setBtnTitle('Search');
    }
    if (availableTrucksState?.lanes?.length > 0) {
      setBtnTitle('Book now');
    } else if (!availableTrucksStatus) {
      setBtnTitle('Search');
    } else {
      setBtnTitle('Search');
    }
  }, [availableTrucksState, pickupValue, pickUpDateValue, activeType]);
  const handleFormWatch = () => {
    const reserveData = {
      estimatedPrice: availableTrucksState?.lanes[0].rate,
      companyId: availableTrucksState?.lanes[0].company?.id,
      totalMiles: distance,
      pickupLocation: pickupLocation,
      deliveryLocation: deliveryLocation,
      pickupFullAddress: pickupAddress,
      deliveryFullAddress: deliveryAddress,
      pickupDisplayAddress,
      deliveryDisplayAddress,
      deliveryLatLng: deliveryLatLng,
      pickupLatLng: pickupLatLng,
      selectedDates: pickUpDateValue,
      equipmentType: activeType,
      weight: watch('weight'),
    };
    if (
      Object.keys(pickupLatLng).length > 0 &&
      Object.keys(deliveryLatLng).length > 0
    ) {
      dispatch(setQuotesPayload(reserveData));
      setReservationData(reserveData);
    }
  };

  useEffect(() => {
    handleFormWatch();
  }, [
    availableTrucksState,
    activeType,
    pickupLocation,
    deliveryLocation,
    pickUpDateValue,
    deliveryLatLng,
    pickupLatLng,
    watch('weight'),
  ]);

  useEffect(() => {
    setAvailableTrucksState(null);
    setBtnTitle('Search');
  }, [
    activeType,
    pickupLocation,
    deliveryLocation,
    pickUpDateValue,
    deliveryLatLng,
    pickupLatLng,
    watch('weight'),
  ]);

  useEffect(() => {
    setTruckTypes(truckTypeIconsName);
  }, []);

  useEffect(() => {
    if (equipmentTypes && equipmentTypes.length > 0) {
      const equipmentTypesIndices = {};
      equipmentTypes.forEach((value, index) => {
        equipmentTypesIndices[value] = index;
      });

      const filteredTruckTypes = truckTypeIconsName
        .filter((truckType) => equipmentTypes.includes(truckType.value))
        .sort(
          (a, b) =>
            equipmentTypesIndices[a.value] - equipmentTypesIndices[b.value]
        );

      const remainingIndices = truckTypeIconsName
        .map((item) => item.value)
        .filter((value) => !equipmentTypes.includes(value));

      const reorderedTruckTypes = [
        ...filteredTruckTypes,
        ...remainingIndices.map((value) =>
          truckTypeIconsName.find((item) => item.value === value)
        ),
      ];

      setTruckTypes(reorderedTruckTypes);
    }
  }, [equipmentTypes]);

  const handleSearch = () => {
    if (availableTrucksState?.lanes.length > 0) {
      setBookBtn(true);
      onComplete();
    }
  };

  const handleActiveType = (item) => {
    if (activeType === item.value) {
      setActiveType(null);
    } else {
      setActiveType(item.value);
    }
  };

  const handleGoogleOnInput = (e, field) => {
    setAvailableTrucksState(null);
    setBtnTitle('Search');
    field.onChange(e);
  };

  const infoNotificationToast = (message) => {
    return toast.info(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: 'colored',
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          className="mt-2"
        >
          <Grid item xs={5.5}>
            <Controller
              name="pickup"
              control={control}
              defaultValue=""
              rules={{ required: 'Pickup location is required' }}
              render={({ field, fieldState }) => {
                pickupField = field;
                return (
                  <div>
                    <GoogleAutoCompleted
                      defaultAddress={{
                        city: quotesPayload?.pickupLocation.city,
                        state: quotesPayload?.pickupLocation.state,
                      }}
                      onPlaceSelected={(
                        place,
                        formattedAddress,
                        googleFormattedAddress
                      ) => {
                        if (place.geometry) {
                          const { location } = place.geometry;
                          setPickupAddress(googleFormattedAddress);
                          setPickupLatLng({
                            lat: location.lat(),
                            lng: location.lng(),
                          });
                        }
                        field.onChange(formattedAddress);
                      }}
                      setPickupValue={(data) => {
                        setPickupValue(data);
                        setPickupDisplayAddress(data);
                      }}
                      placeholder="Pickup city state"
                      value={pickupDisplayAddress}
                      {...field}
                      onInput={(e) => handleGoogleOnInput(e, field)}
                    />

                    {errors.pickup && (
                      <Typography variant="caption" color="error">
                        {fieldState.error?.message}
                      </Typography>
                    )}
                  </div>
                );
              }}
            />
          </Grid>
          <Grid item xs={1} sx={{ textAlign: 'center' }} className="f-b">
            to
          </Grid>
          <Grid item xs={5.5}>
            <Controller
              name="delivery"
              control={control}
              defaultValue=""
              rules={{ required: 'Delivery location is required' }}
              render={({ field }) => {
                deliveryField = field;

                return (
                  <div>
                    <GoogleAutoCompleted
                      defaultAddress={{
                        city: quotesPayload?.deliveryLocation.city,
                        state: quotesPayload?.deliveryLocation.state,
                      }}
                      onPlaceSelected={(
                        place,
                        formattedAddress,
                        googleFormattedAddress
                      ) => {
                        if (place.geometry) {
                          const { location } = place.geometry;
                          setDeliveryAddress(googleFormattedAddress);
                          setDeliveryLatLng({
                            lat: location.lat(),
                            lng: location.lng(),
                          });
                        }
                        field.onChange(formattedAddress);
                      }}
                      setPickupValue={(data) => {
                        setPickupValue(data);
                        setDeliveryDisplayAddress(data);
                      }}
                      placeholder="Delivery city state"
                      {...field}
                      value={deliveryDisplayAddress}
                      onInput={(e) => handleGoogleOnInput(e, field)}
                    />
                    {errors.delivery && (
                      <Typography variant="caption" color="error">
                        {errors.delivery.message}
                      </Typography>
                    )}
                  </div>
                );
              }}
            />
          </Grid>
          <Grid item xs={5.5} className="mt-2 f-b">
            <FormLabel required className="form-label">
              Pickup Date Range
            </FormLabel>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5.5} className="mt-2 f-b">
            Weight
          </Grid>
          <Grid item xs={5.5} className="pt-0">
            <Controller
              name="pickUpDate"
              control={control}
              defaultValue={
                quotesPayload?.selectedDates
                  ? quotesPayload?.selectedDates
                  : selectedDates
              }
              rules={{
                required: 'Pickup date is required',
              }}
              render={({ field: { value, onChange } }) => {
                return (
                  <Flatpickr
                    field={value}
                    value={value}
                    onChange={onChange}
                    mode={'range'}
                    placeholder={'Select a date range'}
                  />
                );
              }}
            />
            {errors.pickUpDate && (
              <Typography variant="caption" color="error">
                {errors.pickUpDate.message}
              </Typography>
            )}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5.5} className="pt-0">
            <Controller
              name="weight"
              control={control}
              defaultValue={quotesPayload?.weight ? quotesPayload.weight : ''}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  size={'small'}
                  type="number"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} className="f-b">
            Equipment Type
          </Grid>
          <Grid item xs={12}>
            <div className="d-flex flex-wrap w-100 justify-center mt-10">
              {truckTypes.map((item, index) => (
                <div className="component-wrapper mt-4" key={index}>
                  <div className="equipment-type-component">
                    <div
                      className={`truck-type equipment-type-style ${
                        activeType === item.value ? 'bg-color' : ''
                      } ${equipmentTypes.includes(item.value) ? '' : 'd-none'}`}
                      onClick={() => handleActiveType(item)}
                    >
                      <img
                        alt="img"
                        src={item.icon}
                        className={`clickable-img ${
                          activeType === item.value ? 'truck-type-active' : ''
                        }`}
                      />
                      <span
                        style={{ fontSize: '10px' }}
                        className={item.value !== `drayage` ? 'mt-2' : ''}
                      >
                        {item.title}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Grid>
          {availableTrucksState?.lanes?.length > 0 && (
            <>
              <Grid item xs={12} className="f-b">
                Rate
              </Grid>
              <Grid item xs={12} className="pt-0">
                <span
                  style={{
                    color: 'blue',
                    fontWeight: 'bold',
                    fontSize: '30px',
                  }}
                >
                  ${parseFloatWithTrim(availableTrucksState?.lanes[0].rate)}
                </span>
              </Grid>
            </>
          )}
          {!availableTrucksStatus && (
            <>
              <Grid item xs={12}>
                <span
                  style={{
                    color: 'red',
                    fontWeight: 'bold',
                    fontSize: '20px',
                  }}
                >
                  We do not currently run this lane
                </span>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            {loading ? (
              <RateLoader />
            ) : (
              <ButtonPrimary
                title={btnTitle}
                type={
                  availableTrucksState?.lanes?.length > 0 ? 'button' : 'submit'
                }
                disabled={!companyData?.carrierCompanyId || distanceLoading}
                handleClick={() => handleSearch()}
              />
            )}
          </Grid>
        </Grid>
        <GoogleMaps
          origin={pickupAddress}
          destination={deliveryAddress}
          onDistanceChange={setDistance}
          setPickupLocation={setPickupLocation}
          setDeliveryLocation={setDeliveryLocation}
          distanceLoading={setDistanceLoading}
        />
      </form>
    </div>
  );
};

export default React.memo(InstantQuoteForm);
