import React, { useState } from "react";
import Carrier from "./components/Carrier";
import ShipperAndBroker from "./components/ShipperAndBroker";
import "./index.scss";
const HowItWorks = () => {
  const [activeTab, setActiveTab] = useState("Carrier");
  return (
    <div>
      <div className="mt-5">
        <h2 className="text-center h1 fw-medium">
          You're just 3 steps away from <br /> your next getaway
        </h2>

        <div>
          <div className="mt-5 gap-4 d-flex justify-content-center">
            <button
              className={`how-it-works-tabs ${activeTab === "Carrier" ? 'active' : ''}`}
              onClick={() => setActiveTab("Carrier")}
            >
              Carrier
            </button>
            <button
              className={`how-it-works-tabs ${activeTab !== "Carrier" ? 'active' : ''}`}
              onClick={() => setActiveTab("ShipperAndBroker")}
            >
              Shipper & Broker
            </button>
          </div>
          <div>
              {activeTab === "Carrier" ? <Carrier /> : <ShipperAndBroker />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
