

import React from 'react';
import "./input.scss";
const Input = React.forwardRef(
    ({ label, placeholder, type, className, onInput, register }, ref) => {
      return (
        <div className={`form-input ${className}`}>
             <label className='label'>{label}</label>
             <input className='input' {...register} type={type} placeholder={placeholder}  onInput={onInput} />
        </div>
      );
    }
  );


export default Input;