import {
  searchAvailableTrucks,
  reserveRequestFromCarrierWebsite,
} from '../../api/truck';
import {
  AVAILABLE_TRUCKS,
  ERROR,
  RESERVE_TRUCKS,
  SET_AVAILABLE_TRUCKS_STATUS,
  SEARCH_QUOTES_PAYLOAD,
  CLEAR_QUOTES_PAYLOAD,
  CLEAR_QUOTES_DATA
} from '../contents/actionsTypes';
import { toast } from 'react-toastify';

export const availableTrucks = (payload) => {
  return async (dispatch) => {
    try {
      const responseData = await searchAvailableTrucks(payload);
      if (!responseData.result) {
        dispatch(setAvailableTrucksStatus(false));
        // return toast.error(responseData?.message, {
        //   position: toast.POSITION.BOTTOM_RIGHT,
        //   theme: 'colored',
        // });
      } else {
        dispatch(setAvailableTrucksStatus(true));
        dispatch({ type: AVAILABLE_TRUCKS, payload: responseData.payload });
      }
      return responseData;
    } catch (error) {
      dispatch({ type: ERROR, payload: error });
      return toast.error(error?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'colored',
      });
    }
  };
};

export const setAvailableTrucksStatus = (status) => {
  return {
    type: SET_AVAILABLE_TRUCKS_STATUS,
    payload: status,
  };
};

export const setQuotesPayload = (payload) => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_QUOTES_DATA });
    dispatch({ type: SEARCH_QUOTES_PAYLOAD, payload });
  }
};


export const clearExistsData = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_QUOTES_PAYLOAD });
  }
}

export const reserveTrucks = (payload) => {
  return async (dispatch) => {
    try {
      const responseData = await reserveRequestFromCarrierWebsite(payload);
      dispatch({ type: RESERVE_TRUCKS, payload: responseData.payload });
      if (responseData.result) {
        return toast.success('Reserve request sent successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'colored',
        });
      } else {
        return toast.error(responseData?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'colored',
        });
      }
    } catch (error) {
      dispatch({ type: ERROR, payload: error });
      return toast.error(error?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'colored',
      });
    }
  };
};

export const clearPreviousQuoteData  = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_QUOTES_DATA });
  }
}