import React, { useState } from 'react';
import './index.scss';
// import ButtonOutline from '../../../components/ui/Buttons/ButtonOutline';
// import ButtonPrimary from '../../../components/ui/Buttons/ButtonPrimary';
import localTrasit from '../../../assets/images/local_transit.png';
import realtimeShipment from '../../../assets/images/realtime_shipment.png';
import proofOfDelivery from '../../../assets/images/proof_of_delivery.png';
import fasterTrasit from '../../../assets/images/faster_transit.png';
import significantSaving from '../../../assets/images/significant_savings.png';
import trustedCarriers from '../../../assets/images/trusted_carriers.png';
import paperlessProcess from '../../../assets/images/paperless_process.png';
import InstantQuoteForm from './instantQuoteForm';
import ReservationForm from './reservationForm';
import Popup from '../../../components/ui/PopUp';
import { useDispatch } from 'react-redux';
import { reserveTrucks, clearExistsData} from "../../../stores/actions/truckActions";
import LinearStepper from '../../../components/ui/Stepper/LinearStepper';
import { useDateFormat } from "../../../hooks/useDateFormat";

const learnMore = [
  {
    id: 1,
    title: 'Same Day Local Transit',
    content:
      'Your freight is automatically connected with the nearest driver which allows it to be delivered faster than ever before',
    img: localTrasit,
  },
  {
    id: 2,
    title: 'Real-Time Shipment Tracking',
    content:
      'Mothership pinpoints the exact location of our partner drivers to provide you with in-depth shipment tracking and real-time notifications. You’ll never miss a beat',
    img: realtimeShipment,
  },
  {
    id: 3,
    title: 'Instant Proof of Delivery',
    content:
      'We capture an electronic signature upon pick-up and delivery which creates a proof of delivery for you to instantly view or download',
    img: proofOfDelivery,
  },
];

const feature = [
  {
    id: 1,
    title: 'Faster Transit',
    desc: 'Knowing exactly where drivers are at every moment lets us optimize trucking routes to provide lightning-fast delivery',
    img: fasterTrasit,
  },
  {
    id: 2,
    title: 'Significant Savings',
    desc: 'Our efficient dispatch, routing, and freight aggregation technologies help drivers save time and gas. This means lower rates for you',
    img: significantSaving,
  },
  {
    id: 3,
    title: 'Trusted Carriers',
    desc: 'We ensure that your cargo is in safe hands by utilizing only the best local drivers, who are all experienced, insured and well-vetted before joining our platform',
    img: trustedCarriers,
  },
  {
    id: 4,
    title: 'Paperless Process',
    desc: 'Skip the paperwork and find all your documents neatly organized in our dashboard ready to view or download from anywhere',
    img: paperlessProcess,
  },
];

const LearnMoreCard = ({ title, content, image, imagePosition }) => {
  let direction = 'row';
  if (imagePosition === 'right') {
    direction = 'row flex-row-reverse';
  }
  return (
    <div className="container mt-5 mb-5">
      <div className={direction}>
        <div className="col-md-6">
          <div className="component d-flex align-items-center justify-content-end px-5 h-100">
            <img src={image} alt="img" className="--image" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex align-items-center h-100">
            <div className="pl-5">
              <div className="component">
                <h3 className="--text font-weight-bolder">{title}</h3>
              </div>
              <div className="component">
                <p className="mt-4 --text color-small">{content}</p>
              </div>
              {/* Hide Button Task-1095 */}
              {/* <ButtonOutline
                title="LEARN MORE"
                className="mt-3"
                sx={{ border: '1px solid #7367F0', color: '#7367F0' }}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FeatureCard = ({ title, desc, img }) => {
  return (
    <div className="col-md-6">
      <div className="component">
        <img
          src={img}
          layout="fixed"
          alt="img"
          width={150}
          height={150}
          className="--image"
        />
      </div>
      <div>
        <div className="component-wrapper mt-4">
          <div className="component">
            <div className="--text h3 font-weight-bold">{title}</div>
          </div>
        </div>
        <div className="component-wrapper mt-4">
          <div className="component">
            <div className="--text color-small">{desc}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Instant = () => {
  const dispatch = useDispatch();
  const [bookBtn, setBookBtn] = useState(false);
  const [reservationData, setReservationData] = useState(null);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [payloadData, setPayloadData] = useState(null);
  const [activeStep, setActiveStep] = useState(1);
  const closePopup = () => {
    setPopupOpen(false);
  };
  const { formatDate } = useDateFormat();

  const handleReservationSubmit = (data) => {
    const ccEmails = data.ccEmails ? data.ccEmails.split(',') : [];
    setPopupOpen(true);

    const reserveData = {
      estimatedPrice: reservationData?.estimatedPrice,
      companyId: reservationData?.companyId,
      shipperBrokerDetails: {
        firstName: data.firstName,
        lastName: data.lastName,
        companyName: data.companyName,
        phone: data.phone,
        email: data.email,
        ccEmails: ccEmails,
      },
      additionalInformation: {
        totalMiles: reservationData?.totalMiles,
        calculateUsing: 'google_maps',
      },
      pickupDetails: [
        {
          city: reservationData?.pickupLocation?.city,
          state: reservationData?.pickupLocation?.state,
          zipCode: reservationData?.pickupLocation?.zipCode,
          latitude: reservationData?.pickupLatLng?.lat,
          longitude: reservationData?.pickupLatLng?.lng,
          date: formatDate(data.pickUpDate[0]),
          weightInLbs: data.weightInLbs,
        },
      ],
      deliveryDetails: [
        {
          city: reservationData?.deliveryLocation?.city,
          state: reservationData?.deliveryLocation?.state,
          zipCode: reservationData?.deliveryLocation?.zipCode,
          latitude: reservationData?.deliveryLatLng?.lat,
          longitude: reservationData?.deliveryLatLng?.lng,
          date: formatDate(data.deliveryDate[0]),
        },
      ],
    };
    setPayloadData(reserveData);
  };
  const reserveTruck = () => {
    dispatch(reserveTrucks(payloadData));
    dispatch(clearExistsData());
    setReservationData(null);
    handleGoBack();
    setPopupOpen(false);
  };
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleGoBack = () => {
    setBookBtn(false);
    setActiveStep(1);
  };

  const handleReservationData = (data) => {
    if (Object.keys(data).length > 0) {
      setReservationData(data);
    }
  };

  return (
    <div id="template" data-component-id="instant">
      <main className="--inner">
        <section className="--section fixed">
          <div className="component">
            <div className="--bg-image">
              <img
                src={require('../../../assets/images/hero_banner.png')}
                alt="bg img"
                className="instant-bg-img --image"
              />
            </div>
          </div>
          <div className="position-relative">
            <div className="component-wrapper pt-5">
              <div className="component">
                <p className="--text text-h2 font-weight-bold color-white mt-5">
                  Last Mile Freight, <br /> Lightning Fast
                </p>
              </div>
            </div>
            <div className="component-wrapper">
              <div className="component">
                <p className="--text text-center color-white mt-5">
                  Connect your freight to nearby trucks for same-day delivery or
                  go further with the most reliable national carriers
                </p>
              </div>

              {/* <div className="--text bg-img"></div> */}
            </div>

            {/* <div className="text-center mt-5">
              <ButtonPrimary
                title="SEE WHAT SETS US APART"
                sx={{ padding: '10px 30px' }}
              />
            </div> */}
          </div>
        </section>
        <section className="--section fixed">
          <div className="container mt-5">
            <div
              className="card shadow border-0"
              style={{ minHeight: '550px' }}
            >
              <div className="card-body d-flex p-5">
                <div className="row">
                  <div className="col-md-4 d-flex flex-column mt-6 pt-6 justify-content-center">
                    <div className="m-3">
                      <div className="component-wrapper">
                        <div className="component">
                          <div className="--text font-weight-bolder h1">
                            Instant Quotes, <br /> Faster Transit
                          </div>
                        </div>
                      </div>
                      <div className="component-wrapper mt-4">
                        <div className="component">
                          <div className="--text" style={{ color: '#6E6B7B' }}>
                            Get same-day service with liftgate included on local
                            shipments. See all-inclusive rates up front — no
                            negotiation or hidden fees, ever. Plus, get
                            unmatched reliability and premium cargo insurance.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    {/* <div className="col-md-12 d-flex align-items-center">
                <LinearStepper activeStep={activeStep} />

                </div> */}
                    {/* <div className="m-3">
                    <div className="mb-3">
                      <label htmlFor="pickup" className="form-label">
                        Pick-Up Address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Address"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="pickup" className="form-label">
                        Delivery Address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Address"
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="pickup" className="form-label">
                          Type
                        </label>
                        <select className="form-select">
                          <option>Open this select menu</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="pickup" className="form-label">
                          Dimensions
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="L x W x H in."
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <label htmlFor="pickup" className="form-label">
                          Weight
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="0 lbs each"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="pickup" className="form-label">
                          Quantity
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="0"
                        />
                      </div>
                    </div>
                    <ButtonPrimary
                      title="QUOTE NOW ->"
                      sx={{ marginTop: 30, padding: "8px 20px" }}
                      handleClick={handleFunction}
                    />
                  </div> */}
                    <LinearStepper activeStep={activeStep} />
                    {!bookBtn && activeStep === 1 && (
                      <InstantQuoteForm
                        setBookBtn={setBookBtn}
                        setReservationData={handleReservationData}
                        onComplete={() => handleStepChange(2)}
                      />
                    )}
                    {bookBtn && (activeStep === 2 || activeStep === 3) && (
                      <ReservationForm
                        goBack={handleGoBack}
                        onSubmit={handleReservationSubmit}
                        onComplete={() => handleStepChange(3)}
                      />
                    )}

                    {isPopupOpen && activeStep === 3 && (
                      <div>
                        <Popup
                          open={isPopupOpen}
                          onClose={closePopup}
                          title=""
                          content={'Are you sure you want to book this?'}
                          actions={[
                            {
                              label: 'Cancel',
                              onClick: closePopup,
                              color: 'primary',
                              className: 'white-btn',
                            },
                            {
                              label: 'Yes',
                              onClick: reserveTruck,
                              className: '',
                            },
                          ]}
                          width="xs"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="--section">
          <div className="container py-4">
            <div className="d-flex justify-content-between">
              <div className="component">
                <img
                  src={require('../../../assets/images/logo/lya.png')}
                  alt="img"
                  width={200}
                  height={200}
                  className="--image"
                />
              </div>
              <div className="component">
                <img
                  src={require('../../../assets/images/logo/happy_return.png')}
                  layout="fixed"
                  alt="img"
                  height={200}
                  className="--image"
                />
              </div>
              <div className="component">
                <img
                  src={require('../../../assets/images/logo/staples.png')}
                  alt="img"
                  width={200}
                  height={200}
                  className="--image"
                />
              </div>
              <div className="component">
                <img
                  src={require('../../../assets/images/logo/lumi.png')}
                  layout="fixed"
                  alt="img"
                  width={200}
                  height={200}
                  className="--image"
                />
              </div>
              <div className="component">
                <img
                  src={require('../../../assets/images/logo/bird.png')}
                  layout="fixed"
                  alt="img"
                  width={200}
                  height={200}
                  className="--image"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="--section">
          <div className="container pb-5">
            <div className="row">
              <div className="col-md-6">
                <div className="d-flex align-items-center justify-content-end h-100 ">
                  <img
                    src={require('../../../assets/images/network_content.png')}
                    layout="fixed"
                    alt="img"
                    width={450}
                    height={250}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="component">
                  <img
                    src={require('../../../assets/images/carrier_network.png')}
                    alt="img"
                    className="--image"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="--section">
          {/* start  */}
          {learnMore.map((item) => (
            <LearnMoreCard
              key={item.id}
              title={item.title}
              content={item.content}
              image={item.img}
              imagePosition={item.id === 2 ? 'right' : 'left'}
            />
          ))}

          {/* END  */}
        </section>
        <section className="--section">
          <div className="py-5" style={{ background: '#F1F4FF' }}>
            <div className="container">
              <div className="row">
                {feature.map((item) => (
                  <FeatureCard
                    key={item.id}
                    img={item.img}
                    desc={item.desc}
                    title={item.title}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Instant;
