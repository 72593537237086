import React from "react";

function Benefits(props) {
  return (
    <div>
      <div style={{ marginBlock: 50 }}>
        <h2 className="h1">
          We will only be successful <br /> if you are successful.
        </h2>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="mt-5">
              <h5 className="h5 fw-semibold">Zero upfront costs</h5>
              <div className="small-text wrapper-line-height">
                <p>
                  Free to get started. No commitments. <br /> No hidden fees.{" "}
                  <br />
                  Transparent pricing for each shipment <br /> booked through
                  us.
                  <br />
                  We will only charge 3.5% of each shipment.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mt-5">
              <h5 className="h5 fw-semibold">Digital Trucking Company</h5>
              <div className="small-text wrapper-line-height">
                <p>
                  The digital era is here. Digital freight brokers <br /> have
                  seen huge success. Now, it's time to <br />
                  build your digital trucking company. Don't <br /> get left
                  behind.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mt-5">
              <h5 className="h5 fw-semibold">Free tools</h5>
              <div className="small-text wrapper-line-height">
                <p>
                  Get AI-powered tools that billion dollar <br /> companies have
                  for free to manage your
                  <br /> entire operations in on place, even if you
                  <br /> don't find loads through us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Benefits;
