import React from "react";
import Carousel from "../../../components/ui/Carousel/Carousel";
import CarouselCard from "../../../components/ui/Carousel/CarouselCard";
import Range from "../../../components/ui/Range/Range";
import "./index.scss";
import people from "../../../assets/images/about/people.png";
import Team from "./components/Team";

const featureContent = [
  {
    id: 1,
    title: "People",
    desc: "We understand that our people impact the success of our business, and we hire people who are smart, dedicated for ABC.",
    img: people,
  },
  {
    id: 2,
    title: "Customer Service",
    desc: "We strive to provide superior customer service and ensure that every client is completely satisfied with our work.",
    img: people,
  },
  {
    id: 3,
    title: "Support",
    desc: "Tell us what you need and our specialist team will deliver it personally, taking care of all the procedures.",
    img: people,
  },
  {
    id: 4,
    title: "Quality",
    desc: "We are committed to deliver outstanding, cutting edge IT solutions that add real value that goes beyond what is expected.",
    img: people,
  },
];

const carouselData = [
  {
    id: 1,
    title: "Newest Technology Innovations",
    subTitle: "Supply Chain",
    desc: "Liftomatic Material Handling, a materials handling equipment manufacturer, introduced the…",
  },
  {
    id: 2,
    title: "Adding Value to Supply",
    subTitle: "Supply Chain",
    desc: "Demand to provide the optimal customer experience continues to escalate, and companies must…",
  },
  {
    id: 3,
    title: "A Smart Urban Mobility",
    subTitle: "Delivery",
    desc: "In the days before the Internet, selling a business was a slow but straightforward process: The buyer…",
  },
];

const FeatureCard = ({ title, desc, img }) => {
  return (
    <div className="about-card">
      <div className="component">
        <img
          src={img}
          layout="fixed"
          width={80}
          height={80}
          className="--image"
          alt="img"
        />
      </div>

      <div className="component-wrapper">
        <div className="component">
          <div className="mt-3">
            <h2 className="font-weight-bold card-head --text">{title}</h2>
          </div>
        </div>
      </div>

      <div className="component-wrapper">
        <div className="component">
          <div className="mt-3">
            <h2 className="small-text color-small --text">{desc}</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

const About = () => {
  return (
    <div id="template" data-component-id="about">
      <main className="--inner">
        <section className="--section fixed">
          <div className="component">
            <div className="hero-wrapper --bg-image">
              <div className="bg-about-img">
                <img
                  src={require("../../../assets/images/about/hero_banner.jpg")}
                  alt="bg img"
                  className="about-bg-img --image"
                />
                <div>
                  <p>About Us</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="--section">
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-6">
                <div className="component">
                  <img
                    src={require("../../../assets/images/local_transit.png")}
                    layout="fixed"
                    width={500}
                    height={400}
                    className="--image"
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="component-wrapper">
                  <div className="component">
                    <div className="mt-5">
                      <h2 className="font-weight-bold h1 --text">
                        Why <span data-logo="true"></span>
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="component-wrapper">
                  <div className="component">
                    <div className="mt-3">
                      <span className="mt-3 fw-light --text color-small h6">
                        We provide an independent advice and identifying the
                        right fit for you. Services are sourced and procured
                        based on solution specifications provided by our Supply
                        Chain Solution.{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="component-wrapper">
                  <div>
                    <div className="mt-4 component">
                      <span className="mt-3 fw-light --text color-small h6">
                        Our teams work around the clock, 365 days a year, to
                        take care of any unexpected circumstances in which speed
                        is a top priority. We offer a variety of ways to reduce
                        transit times, coordinate and arrange immediate
                        deliveries in the shortest time possible, ideal for both
                        regular and single shipments.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="--section">
          <div className="container mt-5">
            <div>
              <div className="row">
                {featureContent.map((item) => (
                  <div className="col-md-3" key={item.id}>
                    <FeatureCard
                      title={item.title}
                      desc={item.desc}
                      img={item.img}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="--section hidden">
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-6">
                <div className="component-wrapper">
                  <div className="component">
                    <div className="mt-5">
                      <h2 className="font-weight-bold --text h1">
                        Real-Time Shipment Tracking
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="component-wrapper">
                  <div className="component">
                    <div className={`--text mt-3`}>
                      <span className="mt-3 fw-light color-small h6">
                        Mothership pinpoints the exact location of our partner
                        drivers to provide you with in-depth shipment tracking
                        and real-time notifications. You’ll never miss a beat
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="component">
                  <img
                    src={require("../../../assets/images/about/shipment_tracking.png")}
                    layout="fixed"
                    width={500}
                    height={400}
                    className="--image"
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="--section hidden">
          <div className="time-line">
            <div className="text-center">
              <span style={{ color: "#C5BFFF", fontSize: 16 }}>TIMELINE</span>
              <div className="component">
                <h3
                  className="color-white font-weight-bold --text"
                  style={{ fontSize: 40 }}
                >
                  Our Company History
                </h3>
              </div>
            </div>
            <div>
              {/* Timeline  */}
              <div className="timeline">
                <div className="events">
                  <ol>
                    <ul>
                      <li>
                        <div className="component">
                          <p className="selected --text">2003</p>
                        </div>
                      </li>
                      <li>
                        <div className="component">
                          <p className="--text">2007</p>
                        </div>
                      </li>
                      <li>
                        <div className="component">
                          <p className="--text">2010</p>
                        </div>
                      </li>
                      <li>
                        <div className="component">
                          <p className="--text">2013</p>
                        </div>
                      </li>
                      <li>
                        <div className="component">
                          <p className="--text">2016</p>
                        </div>
                      </li>
                      <li>
                        <div className="component">
                          <p className="--text">2018</p>
                        </div>
                      </li>
                    </ul>
                  </ol>
                </div>
              </div>
              {/* End timeline  */}
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="component">
                      <img
                        src={require("../../../assets/images/about/company_history.png")}
                        alt="img"
                        className="--image"
                        width={500}
                        height={400}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column h-100 justify-content-center">
                      <div className="component">
                        <h3 className="font-weight-bold color-white --text">
                          ABC foundation and creating powerful logistics and
                          transport network
                        </h3>
                      </div>
                      <div className="component mt-4">
                        <span
                          className="--text"
                          style={{
                            fontSize: 13,
                            color: "#E1E7FC",
                          }}
                        >
                          2010 – In January, named 2009 Employer of the Year by
                          the Develop our regional economic agency; in April,
                          named a Top freight brokerage firm by Transport Topics
                          magazine; and in July, named a 2010 Great Supply Chain
                          Partner by SupplyChainBrain magazine.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="--section hidden">
          <div className="container mt-5">
            <div className="row">
              <div className="col-md-6">
                <div className="component">
                  <img
                    src={require("../../../assets/images/about/optimizing-bg.png")}
                    layout="fixed"
                    className="--image"
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <Range title="Air Freight" editable={true} value="70" />
                  <Range title="Ocean Freight" editable={true} value="60" />
                  <Range title="Railway Freight" editable={true} value="40" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="--section hidden">
          <div className="container mt-5">
            <div className="text-center">
              <div className="component">
                <span className="--text color-primary">CASE STUDIES</span>
              </div>
              <div className="component">
                <h2 className="font-weight-bold h1 --text">
                  See what we"ve been contributing
                </h2>
              </div>
            </div>
            {/* Carousel  */}
            <div>
              <div className="case-carousel">
                <Carousel className="d-flex gap-5 justify-content-center mt-5">
                  {carouselData.map((item) => (
                    <CarouselCard
                      key={item.id}
                      title={item.title}
                      subTitle={item.subTitle}
                      desc={item.desc}
                    />
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </section>
        {/* Team  */}
        <section className="--section hidden">
          <div className="our-team">
            <Team />
          </div>
        </section>
      </main>
    </div>
  );
};

export default About;
