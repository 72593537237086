import {
  FETCH_PUBLISH,
  FETCH_PUBLISH_SUCCESS,
  FETCH_PUBLISH_FAILURE,
} from "../contents/actionsTypes";

const initialState = {
  publish: {},
  isLoading: false,
};

const publishReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_PUBLISH:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_PUBLISH_SUCCESS:
      return {
        ...state,
        publish: payload,
        isLoading: false,
      };
    case FETCH_PUBLISH_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default publishReducer;
