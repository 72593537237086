export const timezoneOptions = () => {
  const timezone = {
    'America/Los_Angeles': 'Pacific Time - US and Canada',
    'America/Edmonton': 'Mountain Time - US and Canada',
    'America/Winnipeg': 'Central Time - US and Canada',
    'America/New_York': 'Eastern Time - US and Canada',
    'America/Halifax': 'Atlantic Time',
  };
  return timezone;
};

export const GoogleAutoCompleteOptions = {
  API_KEY: 'AIzaSyD3Srz6dpiK1Pl6CcC9r5ZfVQ9XXCJ6sWs',
  fields: ['geometry', 'formatted_address', 'address_components'],
  countries: ['us', 'ca'],
};

export const appConstants = {
  equipmentTypes: [
    { title: 'Van', value: 'van' },
    { title: 'Reefer', value: 'reefer' },
    { title: 'Flatbed', value: 'flatbed' },
    { title: 'Step Deck', value: 'stepDeck' },
    { title: 'Power Only', value: 'powerOnly' },
    { title: 'Conestoga', value: 'conestoga' },
    { title: 'Box Truck', value: 'boxTruck' },
    { title: 'Sprinter', value: 'sprinter' },
    { title: 'Drayage', value: 'drayage' },
    { title: 'Intermodal', value: 'intermodal' },
  ],
  webpages: [
    { title: 'About', value: 'About', route: '/about' },
    { title: 'Contact Us', value: 'Contact Us', route: '/contact-us' },
    { title: 'Tracking', value: 'Tracking', route: '/tracking' },
    { title: 'Blog', value: 'Blog', route: '/blogs' },
  ],
};
