import React, { useEffect, useState } from "react";
import "./index.scss";
import $ from "jquery";
function TextEditor({
  edtiorText,
  isNumber,
  setTemplateDataToHtml,
  getValidity,
  min,
  max,
}) {
  const [text, setText] = useState(edtiorText);
  const [numberValue, setNumberValue] = useState(0);
  const initialErrorState = {
    isShow: false,
    text: "",
  };
  const [error, setError] = useState(initialErrorState);
  useEffect(() => {
    if (isNumber) {
      if (edtiorText.includes("%")) {
        setNumberValue(Number(edtiorText.replace("%", "")));
      } else {
        setNumberValue(Number(edtiorText));
      }
    } else {
      setText(edtiorText);
    }
  }, [edtiorText, isNumber]);
  const handleTextEdit = ($event) => {
    $(".active").find(".--text").html($event.target.innerHTML);
    setTemplateDataToHtml();
  };

  const onNumberChange = (value) => {
    setNumberValue(value);
    const innerText = Number(value);
    if (innerText && !isNaN(innerText) && innerText >= min && innerText <= max) {
      $(".active")
        .find(".--text")
        .html(innerText + "%");
      setError(initialErrorState);
      getValidity(initialErrorState.isShow);
    } else if (innerText === 0) {
      $(".active")
        .find(".--text")
        .html(innerText + "%");
      setError(initialErrorState);
      getValidity(initialErrorState.isShow);
    } else {
      const rangeErrorObject = {
        isShow: true,
        text: `Please enter valid number between ${min} to ${max}`,
      };
      setError(rangeErrorObject);
      getValidity(rangeErrorObject.isShow);
    }
    setTemplateDataToHtml();
  };
  return (
    <>
      {!isNumber ? (
        <div
          contentEditable
          suppressContentEditableWarning
          className="editable-container"
          onInput={handleTextEdit}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      ) : (
        <>
          <input
            type="number"
            value={numberValue}
            className="number-input form-control"
            min={0}
            max={100}
            onInput={(e) => onNumberChange(e.target.value)}
          />
          {error.isShow && <div className="error">{error.text}</div>}
        </>
      )}
    </>
  );
}

export default TextEditor;
