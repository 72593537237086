import React from "react";
import { BiSave, BiImage } from "react-icons/bi";
import { MdOutlineForum } from 'react-icons/md'
import { AiOutlinePlus } from "react-icons/ai";
import { IoTextOutline } from "react-icons/io5";
import "./index.scss";

function Menu({ handlePublish, handleAddText, handleAddImage, handleAddBlog }) {
  const iconSize = 25;
  const iconColor = "#fff";
  return (
    <div className="d-flex justify-content-center gap-4 align-items-center">
      <div className="dropdown">
        <AiOutlinePlus
          color={ iconColor }
          size={ iconSize }
          className="dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        />
        <ul className="dropdown-menu menu-elements-dropdown">
          <li onClick={ handleAddText }>
            <IoTextOutline color="#fff" size={ 20 } />

            <p className="dropdown-item m-0 color-white">Text</p>
          </li>
          <li onClick={ handleAddImage }>
            <BiImage color="#fff" size={ 20 } />
            <p className="dropdown-item m-0 color-white">Image</p>
          </li>
          <li onClick={ handleAddBlog }>
            <MdOutlineForum color="#fff" size={ 20 } />
            <p className="dropdown-item m-0 color-white">Blog</p>
          </li>
        </ul>
      </div>
      <BiSave color={ iconColor } size={ iconSize } onClick={ handlePublish } />
    </div>
  );
}

export default Menu;
