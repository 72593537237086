import React from 'react';
import './index.scss';

const Loader = () => {
  return (
    <div className="rate-loader-container">
      <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="loading-text">Rate is loading</div>
    </div>
  );
};

export default Loader;
