// const isProd = true;
const Config = {
  SERVICE_URL: "http://127.0.0.1:8000/api",
  CDN_URL: "",
  S3_URL: "https://ezpapel-test.s3.us-west-2.amazonaws.com",
  S3_HTML_DIRECTORY: "truckpedia/carrierWebsite/html",
  S3_IMAGE_DIRECTORY: "truckpedia/carrierWebsite/userUploadImages",
  TRUCKPEDIA_ORIGIN: "localhost",
  TIMEOUT: 10000,
  GOOGLE_MAPS_KEY: "",
  FIREBASE: {},
  IS_PROD: false,
  NODE_SERVER_URL: "http://127.0.0.1:3000/api",
};

const ProdConfig = {
  SERVICE_URL: "https://dashboard.truckpedia.io/api",
  S3_URL:
    "https://amplifyezpapel5b2fc83bd804424b917a37537a4553a6153242-dev.s3.us-west-2.amazonaws.com",
  S3_HTML_DIRECTORY: "truckpedia/carrierWebsite/html",
  S3_IMAGE_DIRECTORY: "truckpedia/carrierWebsite/userUploadImages",
  TRUCKPEDIA_ORIGIN: "carriers.truckpedia.io",
  TIMEOUT: 10000,
  GOOGLE_MAPS_KEY: "",
  FIREBASE: {},
  IS_PROD: true,
  NODE_SERVER_URL: "https://api23.truckpedia.io/api",
};

/* Decide config values based upon build type: Debug/Release */
export default process.env.NODE_ENV && process.env.NODE_ENV === "development"
  ? Config
  : ProdConfig;
