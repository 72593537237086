/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import config from "../config";
import useQuery from "../hooks/useQuery";
import Navbar from "../layouts/truckpedia/Navbar/Navbar";
import { setAccessLevel } from "../stores/actions/accessLevelActions";
import { useSearchParams } from "react-router-dom";
import { setBearerToken } from "../stores/actions/userActions";
function Truckpedia({ setBearerToken }) {
  // eslint-disable-next-line
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const query = useQuery();
  useEffect(() => {
    // if(window.location.hostname.split('.').includes(config.TRUCKPEDIA_ORIGIN) && !query.get("token")){
    //   navigate("/instant-quote")
    // }
    if (config.IS_PROD) {
      // *.truckpedia.io
      if (
        window.location.hostname.split(".").length === 3 &&
        window.location.hostname.includes(
          config.TRUCKPEDIA_ORIGIN.replace("carriers", "")
        )
      ) {
        // zbu.truckpedia.io
        if (window.location.hostname.split(".")[0] !== "carriers") {
          navigate("/instant-quote");
        }
      } else {
        // www.abctrucking.com
        navigate("/instant-quote");
      }
    } else {
      // Non-prod
      if (
        window.location.hostname.split(".").length === 2 &&
        window.location.hostname.split(".")[1] === config.TRUCKPEDIA_ORIGIN &&
        window.location.hostname.split(".")[0] !== "carriers"
      ) {
        navigate("/instant-quote");
      }
    }
  }, []);

  useEffect(() => {
    if (query.get("token")) {
      const accessToken = query.get("token").replaceAll("'", "");
      localStorage.setItem("access_token", accessToken);
      setBearerToken(accessToken);
      if (searchParams.get("token")) {
        searchParams.delete("token");
        setSearchParams(searchParams);
      }
      navigate("/instant-quote");
    }
  }, [query]);
  return (
    <>
      <Navbar />
      <Outlet />
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  accessLevel: (accessLevel) => dispatch(setAccessLevel(accessLevel)),
  setBearerToken: (token) => dispatch(setBearerToken(token)),
});

export default connect(null, mapDispatchToProps)(Truckpedia);
