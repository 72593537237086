import apiModule from "../services/apiModule";

const { apiClient } = apiModule();

const registerTruckpedia = async (payload) => {
  apiClient.setContentType("application/json");
  const response = await apiClient.post(
    "truckpedia/auth/carrier-one-step-register",
    { ...payload }
  );
  if (response.data && response.result) {
    return response.data.payload;
  }

  return response.data;
};

const loginCarrierTruckpedia = async (payload) => {
  const response = await apiClient.post("auth/login", { ...payload });
  if (response.data && response.result) {
    return response.data.payload;
  }

  return response.data;
};

const getAuthUser = async () => {
  const response = await apiClient.get("auth/user");
  if (response.data && response.result) {
    return response.data.payload;
  }

  return response.data;
};
// Route::post('/auth/carrier-register', 'AuthController@register');
// Route::post('/auth/shipper-register', 'TruckpediaAuthController@shipperRegister');
// Route::post('/auth/broker-register', 'TruckpediaAuthController@brokerRegister');
const shipperRegister = async (payload) => {
  const response = await apiClient.post("trucking/auth/shipper-register", payload);

  if (response.data && response.result) {
    return response.data.payload;
  }

  return response.data;
};

const carrierRegister = async (payload) => {
  const response = await apiClient.post("trucking/auth/carrier-register", payload);

  if (response.data && response.result) {
    return response.data.payload;
  }

  return response.data;
};
const brokerRegister = async (payload) => {
  const response = await apiClient.post("trucking/auth/broker-register", payload);

  if (response.data && response.result) {
    return response.data.payload;
  }

  return response.data;
};

export { registerTruckpedia, loginCarrierTruckpedia, getAuthUser, shipperRegister, carrierRegister, brokerRegister };
