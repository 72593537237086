import axios from "axios";
import apiModule from "../services/apiModule";

const { apiClient } = apiModule();

// const getTemplateContentApi = async (businessUrl) => {
//   apiClient.setContentType("application/json");
//   const response = await apiClient.get(
//     `truckpedia/carrierWebsite/${businessUrl}`
//   );
//   if (response.data && response.result) {
//     return response.data.payload;
//   }

//   return response.data;
// };

// const getTemplateContentApi = async (payload) => {
//   apiClient.setContentType("application/json");
//   const response = await apiClient.post(`truckpedia/getCarrierWebsite`, payload);
//   if (response.data && response.result) {
//     return response.data.payload;
//   }

//   return response.data;
// };

const getTemplateContentApi = async (payload) => {
  apiClient.setContentType("application/json");
  const response = await apiClient.post(`truckpedia/getCarrierWebsite`, payload);
  if (response.data && response.result) {
    return response.data.payload;
  }

  return response.data;
};

const postTemplateContent = async (payload) => {
  apiClient.setContentType("application/json");
  const response = await apiClient.post(`truckpedia/carrierWebsite`, payload);
  if (response.data && response.result) {
    return response.data.payload;
  }

  return response.data;
};

const getCarrierWebsiteImageUploadUrls = async (payload) => {
  apiClient.setContentType("application/json");
  const response = await apiClient.post(
    `truckpedia/getCarrierWebsiteImageUploadUrls`,
    payload
  );
  if (response.data && response.result) {
    return response.data.payload;
  }

  return response.data.payload;
};

const getImagesUrl = async (payload) => {
  apiClient.setContentType("application/json");
  const response = await apiClient.post(`v2/load/files/get-upload-urls`, payload);
  if (response.data && response.result) {
    return response.data.payload;
  }

  return response.data.payload;
};

const uploadFileToS3 = async (url, file) => {
  const response = await axios.put(url, file);
  if (response.data && response.result) {
    return response.data;
  }

  return response.data;
};

const readFileFromS3 = async (url) => {
  const response = await axios.get(url);
  if (response.data && response.result) {
    return response.data;
  }

  return response.data;
};

const carrierWebsiteUrl = async (payload) => {
  apiClient.setContentType("application/json");
  const response = await apiClient.post(
    `truckpedia/carrierWebsiteUrl`,
    payload
  );
  if (response.data && response.result) {
    return response.data.payload;
  }

  return response.data;
};

const createBlog = async (payload) => {
  apiClient.setContentType('application/json');
  const response = await apiClient.post(
    'truckpedia/companyWebsite/blog/create',
    { ...payload }
  );
  if (response.data && response.result) {
    return response.data.payload;
  }
  return response.data;
};

const updateBlog = async (payload) => {
  apiClient.setContentType('application/json');
  const response = await apiClient.patch(
    'truckpedia/companyWebsite/blog/edit',
    { ...payload }
  );
  if (response.data && response.result) {
    return response.data.payload;
  }
  return response.data;
};

const fetchBlogs = async (payload) => {
  apiClient.setContentType('application/json');
  const response = await apiClient.post(
    'truckpedia/companyWebsite/blog/getBlogs',
    { ...payload }
  );
  if (response.data && response.result) {
    return response.data.payload;
  }
  return response.data;
};

const fetchBlogDetails = async (id) => {
  apiClient.setContentType('application/json');
  const response = await apiClient.get(
    `truckpedia/companyWebsite/blog/${id}`
  );
  if (response.data && response.result) {
    return response.data.payload;
  }
  return response.data;
}

export {
  getTemplateContentApi,
  postTemplateContent,
  getCarrierWebsiteImageUploadUrls,
  uploadFileToS3,
  readFileFromS3,
  carrierWebsiteUrl,
  getImagesUrl,
  createBlog,
  fetchBlogs,
  fetchBlogDetails,
  updateBlog
};
