import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import ButtonPrimary from '../../../components/ui/Buttons/ButtonPrimary';
import RateLoader from '../../../components/ui/Loader/RateLoader';

const Popup = ({ open, onClose, title, content, actions, width, height }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={width}
      fullWidth={height === 'full'}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className="f-b">{content}</DialogContent>
      {actions && (
        <DialogActions style={{ justifyContent: 'center', padding: '16px' }}>
          {actions.map((action, index) =>
            action.loading ? (
              <RateLoader processText={''} />
            ) : (
              <ButtonPrimary
                type="button"
                variant="contained"
                handleClick={action.onClick}
                key={index}
                title={action.label}
                className={action.className}
              />
            )
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Popup;
