import axios from 'axios';
import Config from '../config';
import convertToQueryString from '../utils/queryString';

class ApiClient {
	constructor(authToken) {
		const service = axios.create({
			baseURL: Config.SERVICE_URL,
		});
		service.interceptors.response.use(this.handleSuccess, this.handleError);
		this.service = service;
		this.authToken= authToken;
	}

	handleError = (error) => error.response;

	handleSuccess = (response) => response;

	setAuthToken = (authToken) => {
		this.authToken = authToken;
	};

	setContentType(contentType) {
		this.contentType = contentType;
	}

	async get(path, params) {
		let pathUrl = path;
		const headers = {
			'Content-Type': 'application/json',
		};
		if (this.contentType) {
			headers['Content-Type'] = `${this.contentType}`;
		}
		const token = localStorage.getItem('access_token');
		if (token) {
			headers.authorization = `Bearer ${token}`;
		}
		if (params && Object.keys(params).length) {
			pathUrl = `${path}?${convertToQueryString(params)}`;
		}
		const response = await this.service.request({
			method: 'GET',
			url: pathUrl,
			headers,
		});
		return response;
	}

	async patch(path, payload, params) {
		let pathUrl = path;
		const headers = {
			'Content-Type': 'application/json',
		};
		if (this.contentType) {
			headers['Content-Type'] = `${this.contentType}`;
		}
		const token = localStorage.getItem('access_token');
		if (token) {
			headers.authorization = `Bearer ${token}`;
		}
		if (params && Object.keys(params).length) {
			pathUrl = `${path}?${convertToQueryString(params)}`;
		}
		const response = await this.service.request({
			method: 'PATCH',
			headers,
			url: pathUrl,
			responseType: 'json',
			data: payload,
		});
		return response;
	}

	async delete(path, payload, params) {
		let pathUrl = path;
		const headers = {
			'Content-Type': 'application/json',
		};
		if (this.contentType) {
			headers['Content-Type'] = `${this.contentType}`;
		}
		const token = localStorage.getItem('access_token');
		if (token) {
			headers.authorization = `Bearer ${token}`;
		}
		if (params && Object.keys(params).length) {
			pathUrl = `${path}?${convertToQueryString(params)}`;
		}
		const response = await this.service.request({
			method: 'DELETE',
			headers,
			url: pathUrl,
			responseType: 'json',
			data: payload,
		});
		return response;
	}

	async post(path, payload, params) {
		let pathUrl = path;
		const headers = {
			'Content-Type': 'application/json',
		};
		if (this.contentType) {
			headers['Content-Type'] = `${this.contentType}`;
		}
		const token = localStorage.getItem('access_token');
		if (token) {
			headers.authorization = `Bearer ${token}`;
		}
		if (params && Object.keys(params).length) {
			pathUrl = `${path}?${convertToQueryString(params)}`;
		}
		const response = await this.service.request({
			method: 'POST',
			headers,
			url: pathUrl,
			responseType: 'json',
			data: payload,
		});
		return response;
	}

	async put(path, payload, params) {
		let pathUrl = path;
		const headers = {
			'Content-Type': 'application/json',
		};
		if (this.contentType) {
			headers['Content-Type'] = `${this.contentType}`;
		}
		const token = localStorage.getItem('access_token');
		if (token) {
			headers.authorization = `Bearer ${token}`;
		}
		if (params && Object.keys(params).length) {
			pathUrl = `${path}?${convertToQueryString(params)}`;
		}
		const response = await this.service.request({
			method: 'PUT',
			headers,
			url: pathUrl,
			responseType: 'json',
			data: payload,
		});
		return response;
	}
}

export default ApiClient;