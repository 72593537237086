import {
  FETCH_IMAGE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILURE,
} from "../contents/actionsTypes";

const initialState = {
  isLoading: false,
  replaceImages: [],
};

const imagesReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        replaceImages: [payload, ...state.replaceImages],
      };
    case FETCH_IMAGE:
      return {
        ...state,
        isLoading: true,
      };
    case UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default imagesReducer;
