import {
  brokerRegister,
  carrierRegister,
  shipperRegister,
} from "../../api/auth";
import apiModule from "../../services/apiModule";
import { SET_USER_DATA, UPDATE_USER_STATUS, SET_TOKEN } from "../contents/actionsTypes";
const { apiClient } = apiModule();
export const setUserData = (user) => {
  if (user !== null) {
    apiClient.setAuthToken(user.access_token);
  }
  return { type: SET_USER_DATA, payload: user };
};

export function checkAndUpdateUserStatusState(user) {
  return {
    type: UPDATE_USER_STATUS,
    payload: {
      user,
    },
  };
}

export const setBearerToken = (token) => {
  return { type: SET_TOKEN, payload: token };
};

export const register = (payload) => {
  return async (dispatch) => {
    let user;
    if (payload.role === "shipper") {
      user = await shipperRegister(payload);
    }
    if (payload.role === "broker") {
      user = await brokerRegister(payload);
    }
    if (payload.role === "carrier") {
      user = await carrierRegister(payload);
    }
    if (user) {
      dispatch(checkAndUpdateUserStatusState(user));
    }
  };
};
